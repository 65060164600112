import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface RegisterUsersInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
    activationResult : number;
}

@Module
export default class RegisterUserModule extends VuexModule implements RegisterUsersInfo {

    errors = [];
    myList = {} as myvar;
    myDetails = [];
    activationResult = 0;

    get registerUsersList(): myvar {
        return this.myList;
    }

    get registerUsersDetails(): Array<string> {
        return this.myDetails;
    }

    get registerUsersErrors(): Array<string> {
        return this.errors;
    }

    get registerUsersActivatonResult(): number {
        return this.activationResult;
    }

    @Mutation
    [Mutations.SET_REGISTER_USERS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_REGISTER_USERS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_REGISTER_USERS_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Mutation
    [Mutations.SET_REGISTER_USERS_ACTIVATION_RESULT](payload) {
        this.activationResult = payload;
    }

    /*
    @Action
    [Actions.REGISTER_USERS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/register", credentials)
                .then(({ data }) => {                    
                    if(data.status == 201) {                        
                        //store.dispatch(Actions.REGISTER_USERS_LIST, {});
                        resolve();
                    } else {
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_REGISTER_USERS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_REGISTER_USERS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.REGISTER_USERS_VERIFY](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/verifyUser", credentials)
                .then(({ data }) => {                    
                    if(data.status == 200) {                        
                        //store.dispatch(Actions.REGISTER_USERS_LIST, {});
                        this.context.commit(Mutations.SET_REGISTER_USERS_ACTIVATION_RESULT, 1);
                        resolve();
                    } else {
                        this.context.commit(Mutations.SET_REGISTER_USERS_ACTIVATION_RESULT, 0);
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_REGISTER_USERS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_REGISTER_USERS_ERROR, myError);
                    reject();
                });
        });
    }
    */

    @Action
    [Actions.REGISTER_USERS_ACTIVATION](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/activation", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        this.context.commit(Mutations.SET_REGISTER_USERS_ACTIVATION_RESULT, 1);
                        resolve();
                    } else {
                        this.context.commit(Mutations.SET_REGISTER_USERS_ACTIVATION_RESULT, 2);
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_REGISTER_USERS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_REGISTER_USERS_ERROR, myError);
                    reject();
                });
        });
    }

}