import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
//import i18n from "@/core/plugins/i18n";

//imports for app initialization
import AppCore from "@/core/appcore/AppCore";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import SimpleTypeahead from 'vue3-simple-typeahead';
import Maska from 'maska';
import VueCryptojs from 'vue-cryptojs';


import "@/core/plugins/prismjs";
//import i18n from './i18n'
//const app = createApp(App).use(i18n);
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Maska);
app.use(VueCryptojs);

AppCore.init(app);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(SimpleTypeahead);

//console.log("MAIN.TS VueCryptojs Res: "+SHA256("12345678").toString(enc.Hex));
//console.log("MAIN.TS VueCryptojs Res: "+ VueCryptojs.createHash("sha1").update("12345678").digest('hex'));

//app.use(i18n);

app.mount("#app");
