enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    REGISTER_SET_USER_VALUE = "registersetuservalue",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    RESET_PASSWORD = "resetPassword",
    VERIFY_USER = "verifyUser",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    SET_THEME_MODE_ACTION = "setThemeModeAction",
    PROCESS_LOGOUT = "actProcessLogout",
    SET_MYVALUE_ACTION = "setMyValueAction",

    /***********************************/

    GAME_ACCOUNT_TYPE = "gameAccountType",
    GAME_SYMBOL = "gameSymbol",
    GAME_BALANCE = "gameBalance",
    GAME_BALANCE_DEMO = "gameBalanceDemo",
    GAME_AMOUNT = "gameAmount",
    GAME_MIN_AMOUNT = "gameMinAmount",
    GAME_MAX_AMOUNT = "gameMaxAmount",
    GAME_WINRATE = "gameWinRate",
    GAME_OPEN_COUNT = "gameOpenCount",
    GAME_DURATION = "gameDuration",
    GAME_DURATION_LIST = "gameDurationList",
    GAME_DATA_DURATION = "gameDataDuration",
    GAME_TICKERS = "gameTickers",
    GAME_TICKERS_INSERT = "gameTickersInsert",
    GAME_TICKERS_DELETE = "gameTickersDelete",
    GAME_FAVORITE_SYMBOLS = "gameFavoriteSymbols",
    GAME_ISLOADING_CHART = "gameIsloadingchart",
    GAME_VIEWPORT = "gameViewPort",

    SOCKET_RECEIVED_OPEN_GAME =  "socketReceivedOpenGame",
    SOCKET_RECEIVED_CLOSE_GAME =  "socketReceivedCloseGame",
    SOCKET_RECEIVED_BALANCE_CHANGED =  "socketReceivedBalanceChanged",
    SOCKET_RECEIVED_JACKPOT_INFOS =  "socketReceivedJackpotInfos",
    SOCKET_RECEIVED_TREND_INFOS =  "socketReceivedTrendInfos",

    REGISTER_USERS_LIST = "getRegisterUsersList",
    REGISTER_USERS_DETAILS = "registerUserDetails",
    REGISTER_USERS_INSERT = "registerUserInsert",    
    REGISTER_USERS_EDIT = "registerUsersEdit",
    REGISTER_USERS_DELETE = "registerUsersDelete",
    REGISTER_USERS_ORDER = "registerUsersOrder",
    REGISTER_USERS_VERIFY = "registerUserVerify",
    REGISTER_USERS_ACTIVATION = "registerUserActivation",

    SYMBOLS_LIST = "getSymbolsList",
    SYMBOLS_DETAILS = "getSymbolsDetails",
    SYMBOLS_INSERT = "symbolsInsert",
    SYMBOLS_EDIT = "symbolsEdit",
    SYMBOLS_DELETE = "symbolsDelete",
    SYMBOLS_SELECTED = "symbolsSelected",

    FAVORITE_SYMBOLS_LIST = "getFavoriteSymbolsList",
    FAVORITE_SYMBOLS_DETAILS = "getFavoriteSymbolsDetails",
    FAVORITE_SYMBOLS_INSERT = "favoriteSymbolsInsert",
    FAVORITE_SYMBOLS_EDIT = "favoriteSymbolsEdit",
    FAVORITE_SYMBOLS_DELETE = "favoriteSymbolsDelete",

    GAME_LISTS_LIST = "getGameListsList",
    GAME_LISTS_ADD = "getGameListsAdd",
    GAME_LISTS_SETITEM = "getGameListsSetItem",
    GAME_LISTS_SETITEM_FINISHRATE = "getGameListsSetItemFinishRate",
    GAME_LISTS_SETITEM_CANCEL = "getGameListsSetItemCancel",

    DURATIONS_LIST = "DurationsList",
    BALANCES_LIST = "BalancesList",

    WITHDRAWS_LIST = "WithdrawsList",

    DEPOSITS_LIST = "depositsList",
    DEPOSITS_INSERT = "depositsInsert",
    WITHDRAWS_INSERT = "withdrawsInsert",

    BONUS_LIST = "bonusList",

    BANKS_LIST = "banksList",
    BANKS_INSERT = "banksInsert",
    BANKS_DELETE = "banksDelete",

    ACTIONS_LIST = "actionsList",

    JACKPOT_STATS_LIST = "jackpotStatsList",

    ACTION_BONUSSES_LIST = "actionBonussesList",

    AFFILIATES_LIST = "affiliatesList",
    AFFILIATES_ERROR = "affiliatesError",
}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_REGISTER_USER_RESULT = "setRegisterUserResult",
    SET_VERIFY_USER_RESULT = "setVerifyUserResult",
    SET_LOGIN_RESULT = "setLoginResult",
    SET_FORGOT_PASSWORD_RESULT = "setForgotPasswordResult",
    SET_RESET_PASSWORD_RESULT = "setResetPasswordResult",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_THEME_MODE_MUTATION = "setThemeModeMutation",
    SET_PROCESS_LOGOUT = "setProcessLogout",
    SET_MYVALUE = "setMyValue",

    /*************************************/

    SET_GAME_ACCOUNT_TYPE = "setGameAccountType",
    SET_GAME_SYMBOL = "setGameSymbol",
    SET_GAME_BALANCE = "setGameBalance",
    SET_GAME_BALANCEDEMO = "setGameBalanceDemo",
    SET_GAME_AMOUNT = "setGameAmount",
    SET_GAME_MIN_AMOUNT = "setGameMinAmount",
    SET_GAME_MAX_AMOUNT = "setGameMaxAmount",
    SET_GAME_WINRATE = "setGameWinRate",
    SET_GAME_OPEN_COUNT = "setGameOpenCount",
    SET_GAME_DURATION = "setGameDuration",
    SET_GAME_DURATION_LIST = "setGameDurationList",
    SET_GAME_DATA_DURATION = "setGameDataDuration",
    SET_GAME_TICKERS = "setGameTickers",
    SET_GAME_TICKERS_INSERT = "setGameTickersInsert",
    SET_GAME_TICKERS_DELETE = "setGameTickersDelete",
    SET_GAME_FAVORITE_SYMBOLS = "setGameFavoriteSymbols",
    SET_GAME_ISLOADING_CHART = "setGameIsLoadingChart",
    SET_GAME_VIEWPORT = "setGameViewPort",

    SET_SOCKET_RECEIVED_OPEN_GAME ="setSocketReceivedOpenGame",
    SET_SOCKET_RECEIVED_CLOSE_GAME ="setSocketReceivedCloseGame",
    SET_SOCKET_RECEIVED_BALANCE_CHANGED = "setSocketReceivedBalanceChanged",
    SET_SOCKET_RECEIVED_JACKPOT_INFOS = "setSocketReceivedJackpotInfos",
    SET_SOCKET_RECEIVED_TREND_INFOS = "setSocketReceivedTrendInfos",

    SET_REGISTER_USERS_LIST = "setRegisterUsersList",
    SET_REGISTER_USERS_DETAILS = "setRegisterUsersDetails",
    SET_REGISTER_USERS_ERROR = "setRegisterUsersErrors",
    SET_REGISTER_USERS_ACTIVATION_RESULT = "setRegisterUsersActivationResult",

    SET_SYMBOLS_LIST = "setSymbolsList",
    SET_SYMBOLS_DETAILS = "setSymbolsDetails",
    SET_SYMBOLS_ERROR = "setSymbolsErrors",  
    SET_SYMBOLS_SELECTED = "setSymbolsSelected",  

    SET_FAVORITE_SYMBOLS_LIST = "setFavoriteSymbolsList",
    SET_FAVORITE_SYMBOLS_DETAILS = "setFavoriteSymbolsDetails",
    SET_FAVORITE_SYMBOLS_ERROR = "setFavoriteSymbolsErrors",

    SET_GAME_LISTS_LISTA = "setGameListsListA",
    SET_GAME_LISTS_LISTB = "setGameListsListB",
    SET_GAME_LISTS_HISTORY_LIST = "setGameListsHistoryList",
    SET_GAME_LISTS_ADD = "setGameListsAdd",
    SET_GAME_LISTS_SETITEM = "setGameListsSetItem",
    SET_GAME_LISTS_ETITEMS_FINISHRATE = "setGameListsSetItemFinishRate",
    SET_GAME_LISTS_DETAILS = "setGameListsDetails",
    SET_GAME_LISTS_ERROR = "setGameListsErrors",

    SET_DURATIONS_LIST = "setDurationsList",
    SET_DURATIONS_ERROR = "setDurationsError",

    SET_BALANCES_LIST = "setBalancesList",
    SET_BALANCES_ERROR = "setBalancesError",

    SET_DEPOSITS_LIST = "setDepositsList",
    SET_DEPOSITS_ERROR = "setDepositsError",

    SET_WITHDRAWS_LIST = "setWithdrawsList",

    SET_BONUS_LIST = "setBonusList",
    SET_BONUS_ERROR = "setBonusError",

    SET_BANKS_LIST = "setBanksList",
    SET_BANKS_ERROR = "setBanksError",

    SET_ACTIONS_LIST = "setActionsList",
    SET_ACTIONS_ERROR = "setActionsError",

    SET_JACKPOT_STATS_LIST = "setJackpotStatsList",
    SET_JACKPOTS_ERROR = "setJackpotsError",

    SET_ACTION_BONUSSES_LIST = "setActionBonussesList",
    SET_ACTION_BONUSSES_ERROR = "setActionBonussesError",

    SET_AFFILIATES_LIST = "setAffiliatesList",
    SET_AFFILIATES_ERROR = "setAffiliatesError",

}

export { Actions, Mutations };
