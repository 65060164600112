import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface SymbolsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
    selected: string;
}

@Module
export default class SymbolModule extends VuexModule implements SymbolsInfo {

    errors = [];
    myList = {} as myvar;
    myDetails = [];
    selected = "";

    get symbolsList(): myvar {
        return this.myList;
    }

    get symbolsDetails(): Array<string> {
        return this.myDetails;
    }

    get symbolsErrors(): Array<string> {
        return this.errors;
    }

    get symbolsSelecteds(): string {
        return this.selected;
    }

    @Mutation
    [Mutations.SET_SYMBOLS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_SYMBOLS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_SYMBOLS_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Mutation
    [Mutations.SET_SYMBOLS_SELECTED](payload) {
        this.selected = payload;
    }    

    @Action
    [Actions.SYMBOLS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {            
            ApiService.setHeader();
            ApiService.post("/symbols/listV2", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_SYMBOLS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.SYMBOLS_SELECTED](payload) {
        this.context.commit(Mutations.SET_SYMBOLS_SELECTED, payload);
    }

}