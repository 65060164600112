import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface AffiliatesInfo {
    myList: myvar;
    errors: Array<string>;
}

@Module
export default class AffiliateModule extends VuexModule implements AffiliatesInfo {
    errors = [];
    myList = {} as myvar;

    get getAffiliatesList(): myvar {
        return this.myList;
    }

    @Mutation
    [Mutations.SET_AFFILIATES_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AFFILIATES_LIST](prmValue) {
        this.myList = prmValue;
    }

    @Action
    [Actions.AFFILIATES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/affiliateCheck", payload).then(({ data }) => {
                payload.data = data.data;
                this.context.commit(Mutations.SET_AFFILIATES_LIST, payload);
                resolve();
            }).catch(({ response }) => {
                reject();
            });
        });
    }

}