import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface BanksInfo {
    myList: myvar;
    errors: Array<string>;
}

@Module
export default class BankModule extends VuexModule implements BanksInfo {
    errors = [];
    myList = {} as myvar;

    get getBankList(): myvar {
        return this.myList;
    }

    @Mutation
    [Mutations.SET_BANKS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_BANKS_LIST](prmValue) {
        this.myList = prmValue;
    }

    @Action
    [Actions.BANKS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/bankaccounts/get", payload).then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve();
                }).catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.BANKS_INSERT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/bankaccounts/add", payload)
                .then(({ data }) => {
                    if (data.status=== 201) {
                        store.dispatch(Actions.BANKS_LIST, {});
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.BANKS_DELETE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/bankaccounts/remove", payload)
                .then(({ data }) => {
                    if (data.status=== 200) {
                        store.dispatch(Actions.BANKS_LIST, {});
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

}