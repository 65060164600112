import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:id",
    redirect: "/:id",
    component: () => import("@/components/page-layouts/Layout.vue"),
    children: [
      { path: "/dashboard", name: "dashboard-main", component: () => import("@/views/Dashboard.vue"), },
      { path:
            "/goto/:id", name: "dashboard-main-withid", component: () => import("@/views/Dashboard.vue")
        , },
      { path: "/:id", name: "dashboard-main-withid", component: () => import("@/views/Dashboard.vue"), },
    ],
  },
  {
    path: "/", component: () => import("@/components/page-layouts/TestLayout.vue"),
    children: [
      { path: "/testview", name: "test-view", component: () => import("@/views/pesatr/logined/TestView.vue"), },      
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404", name: "404", component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  { 
    path: "/500", name: "500", component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  { 
    path: "/:pathMatch(.*)*", redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { Authorization: JwtService.getToken(), Username:JwtService.getUserName() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
