import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {ScoketsInfo} from "@/store/modules/SocketModule";
import store from "@/store";
import AppCore from "@/core/appcore/AppCore";

export interface GamesInfo {
    ccvAccountType : string,
    ccvSymbol : string;
    ccvBalance: number,
    ccvBalanceDemo: number,
    ccvAmount : number;
    ccvMinAmount : number;
    ccvMaxAmount : number;
    ccvWinRate : number;
    ccvOpenCount : number;
    ccvDuration : number;
    theDataDuration : Array<string>;  //Requested Data
    arrTickers : Array<string>;
    arrFavoriteSymbols : Array<string>;
    arrDurationList: Array<string>;
    isLoadingChart: boolean;
    ccvViewPort: string;
}

@Module
export default class GameModule extends VuexModule implements GamesInfo {
    ccvAccountType = "";
    ccvSymbol = "";
    ccvBalance = 0;
    ccvBalanceDemo  = 0;
    ccvAmount = 0;
    ccvMinAmount = 0;
    ccvMaxAmount = 0;
    ccvWinRate = 0;
    ccvOpenCount = 0;
    ccvDuration = 0;
    theDataDuration = [];
    arrTickers = [];
    arrFavoriteSymbols = [];
    arrDurationList = [];
    isLoadingChart = true;
    ccvViewPort = "";

    get getGameAccountType(): string {
        return this.ccvAccountType;
    }

    get getGameSymbol(): string {
        return this.ccvSymbol;
    }

    get getGameBalance(): number {
        return this.ccvBalance;
    }

    get getGameBalanceDemo(): number {
        return this.ccvBalanceDemo;
    }

    get getGameAmount(): number {
        return this.ccvAmount;
    }

    get getGameMinAmount(): number {
        return this.ccvMinAmount;
    }

    get getGameMaxAmount(): number {
        return this.ccvMaxAmount;
    }

    get getGameWinRate(): number {
        return this.ccvWinRate;
    }

    get getGameOpenCount(): number {
        return this.ccvOpenCount;
    }

    get getGameDuration(): number {
        return this.ccvDuration;
    }

    get getGameDataDuration(): Array<string> {
        return this.theDataDuration;
    }

    get getGameTickers(): Array<string> {
        return this.arrTickers;
    }

    get getGameFavoriteSymbols(): Array<string> {
        return this.arrFavoriteSymbols;
    }

    get getGameDurationList(): Array<string> {
        return this.arrDurationList;
    }

    get getGameIsLoadingChart(): boolean {
        return this.isLoadingChart;
    }

    get getGameViewPort():string {
        return this.ccvViewPort;
    }

    @Mutation
    [Mutations.SET_GAME_ACCOUNT_TYPE](prmPayload) {
        this.ccvAccountType = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_SYMBOL](prmPayload) {
        this.ccvSymbol = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_BALANCE](prmPayload) {
        this.ccvBalance = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_BALANCEDEMO](prmPayload) {
        this.ccvBalanceDemo = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_AMOUNT](prmPayload) {
        this.ccvAmount = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_MIN_AMOUNT](prmPayload) {
        this.ccvMinAmount = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_MAX_AMOUNT](prmPayload) {
        this.ccvMaxAmount = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_WINRATE](prmPayload) {
        this.ccvWinRate = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_OPEN_COUNT](prmPayload) {
        this.ccvOpenCount = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_DURATION](prmPayload) {
        this.ccvDuration = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_DURATION_LIST](prmPayload) {
        this.arrDurationList = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_DATA_DURATION](prmPayload) {
        this.theDataDuration = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_TICKERS](prmPayload) {
        this.arrTickers = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_TICKERS_DELETE](prmPayload) {
        const arrTemp : any = [];
        for (let i=0; i<this.arrTickers.length; i++) {
            const tmpItem = (this.arrTickers[i] as any);
            if (tmpItem.wsTickerName!==prmPayload.Symbol) {
               arrTemp.push(tmpItem);
            }
        }
        this.arrTickers = arrTemp;
    }

    @Mutation
    [Mutations.SET_GAME_TICKERS_INSERT](prmTicker) {

        let isExist = false;
        for (let i=0; i<this.arrTickers.length; i++) {
            const tmpItem = this.arrTickers[i];
            console.log("SET_GAME_TICKERS_INSERT EACH "+(tmpItem as any).wsTickerName);
            if ((tmpItem as any).wsTickerName === prmTicker.wsTickerName) {
                isExist=true;
            }
        }

        if (!isExist) {
            //const arrTemp = [];
            //this.arrTickers.push(prmTicker as never);
            this.arrTickers.unshift(prmTicker as never);
            /*
            const arrTemp = this.arrTickers;
            arrTemp.push(prmTicker as never);
            store.dispatch(Actions.GAME_TICKERS, arrTemp);
             */
        }

        AppCore.prsCore_Game.changeSymbolTab(prmTicker.wsTickerName);
    }

    @Mutation
    [Mutations.SET_GAME_FAVORITE_SYMBOLS](prmPayload) {
        this.arrFavoriteSymbols = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_ISLOADING_CHART](prmPayload) {
        this.isLoadingChart = prmPayload;
    }

    @Mutation
    [Mutations.SET_GAME_VIEWPORT](prmPayload) {
        this.ccvViewPort = prmPayload;
    }

    @Action
    [Actions.GAME_ACCOUNT_TYPE](payload) {
        this.context.commit(Mutations.SET_GAME_ACCOUNT_TYPE, payload);
    }

    @Action
    [Actions.GAME_SYMBOL](payload) {
        this.context.commit(Mutations.SET_GAME_SYMBOL, payload);
    }

    @Action
    [Actions.GAME_BALANCE](payload) {
        this.context.commit(Mutations.SET_GAME_BALANCE, payload);
    }

    @Action
    [Actions.GAME_BALANCE_DEMO](payload) {
        this.context.commit(Mutations.SET_GAME_BALANCEDEMO, payload);
    }

    @Action
    [Actions.GAME_AMOUNT](payload) {
        this.context.commit(Mutations.SET_GAME_AMOUNT, payload);
    }

    @Action
    [Actions.GAME_MIN_AMOUNT](payload) {
        this.context.commit(Mutations.SET_GAME_MIN_AMOUNT, payload);
    }

    @Action
    [Actions.GAME_MAX_AMOUNT](payload) {
        this.context.commit(Mutations.SET_GAME_MAX_AMOUNT, payload);
    }

    @Action
    [Actions.GAME_WINRATE](payload) {
        this.context.commit(Mutations.SET_GAME_WINRATE, payload);
    }

    @Action
    [Actions.GAME_OPEN_COUNT](payload) {
        this.context.commit(Mutations.SET_GAME_OPEN_COUNT, payload);
    }

    @Action
    [Actions.GAME_DURATION](payload) {
        this.context.commit(Mutations.SET_GAME_DURATION, payload);
    }

    @Action
    [Actions.GAME_DURATION_LIST](payload) {
        this.context.commit(Mutations.SET_GAME_DURATION_LIST, payload);
    }

    @Action
    [Actions.GAME_DATA_DURATION](payload) {
        this.context.commit(Mutations.SET_GAME_DATA_DURATION, payload);
    }

    @Action
    [Actions.GAME_TICKERS](payload) {
        this.context.commit(Mutations.SET_GAME_TICKERS, payload);
    }

    @Action
    [Actions.GAME_TICKERS_INSERT](prmTicker) {
        this.context.commit(Mutations.SET_GAME_TICKERS_INSERT, prmTicker);
    }

    @Action
    [Actions.GAME_TICKERS_DELETE](payload) {
        this.context.commit(Mutations.SET_GAME_TICKERS_DELETE, payload);
    }

    @Action
    [Actions.GAME_FAVORITE_SYMBOLS](payload) {
        this.context.commit(Mutations.SET_GAME_FAVORITE_SYMBOLS, payload);
    }

    @Action
    [Actions.GAME_ISLOADING_CHART](payload) {
        this.context.commit(Mutations.SET_GAME_ISLOADING_CHART, payload);
    }

    @Action
    [Actions.GAME_VIEWPORT](payload) {
        this.context.commit(Mutations.SET_GAME_VIEWPORT, payload);
    }

}