
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "app",
    setup() {
        const store = useStore();

        onMounted(() => {
            //console.log("App.vue onMounted");
            //window.addEventListener('click', clickBody);
            //window.addEventListener('mousemove',mousemoveBody);
            window.addEventListener("resize", resizeWindow);
            chekcBrowser();
            //setFavIcon();

            /*
            document.querySelector("#container").requestFullscreen({ navigationUI: "show" })
                .then(function() {

                })
                .catch(function(error) {

                });

             */

            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

            nextTick(() => {
                //console.log("App.vue nextTick");
                initializeComponents();
            });

            function clickBody (prmEvent) {
                //console.log("clickBody Id: "+prmEvent.target.getAttribute("id"));
                if (!prmEvent.target.matches('.' + "mnssys-dropdown-menu-hdr") ) {
                    //console.log("clickBody Id: "+prmEvent.target.getAttribute("id")+" NOT_MATCHED");
                    var arrEls = document.getElementsByClassName("mnssys-dropdown-menu-sub");
                    for (var i=0; i<arrEls.length; i++) {
                        var elEach = arrEls[i] as HTMLElement;
                        elEach.style.display="none";
                    }
                }
            }

            function mousemoveBody (prmEvent) {
                //console.log("mousemoveBody ");
            }

            function resizeWindow (prmEvent) {
                AppCore.prsCore_Game.checkViewPort();
                AppCore.prsCore_Game.callFillChartDimensions();
            }

            function chekcBrowser() {
                //navigator.userAgent
                console.log("chekcBrowser navigator.userAgent: "+navigator.userAgent);
                //console.log("chekcBrowser navigator.userAgentData.mobile: "+navigator.userAgent.mob);
                //
            }

            function setFavIcon() {
                console.log("setFavIcon START");
                var link = document.querySelector("link[rel~='icon']") as any;
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    link.href = libTools.method.getLogoFavIcon();
                    document.head.appendChild(link);
                } else {
                    link.href = libTools.method.getLogoFavIcon();
                }
                //document.title = libTools.method.getPageTitle();
                console.log("setFavIcon link.href: "+link.href);
            }

        });
    },
});
