import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {DepositsInfo} from "@/store/modules/DepositModule";

interface myvar {
    data: Array<string>;
}

export interface BonusesInfo {
    myList: myvar;
    errors: Array<string>;
}

@Module
export default class BonusModule extends VuexModule implements BonusesInfo {
    errors = [];
    myList = {} as myvar;

    get getBonusList(): myvar {
        return this.myList;
    }

    @Mutation
    [Mutations.SET_BONUS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_BONUS_LIST](prmValue) {
        this.myList = prmValue;
    }

    @Action
    [Actions.BONUS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/bonuses", payload).then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_BONUS_LIST, payload);
                    resolve();
                }).catch(({ response }) => {
                    reject();
                });
        });
    }

}