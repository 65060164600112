import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface ScoketsInfo {
    ccvReceivedOpenGame : Array<string>;
    ccvReceivedCloseGame : Array<string>;
    ccvReceivedBalanceChanged : Array<string>;
    ccvReceivedJackpotInfos : Array<string>;
    ccvReceivedTrendInfos : Array<string>;
}

@Module
export default class SocketModule extends VuexModule implements ScoketsInfo {
    ccvReceivedOpenGame = [];
    ccvReceivedCloseGame = [];
    ccvReceivedBalanceChanged = [];
    ccvReceivedJackpotInfos = [];
    ccvReceivedTrendInfos = [];

    get getSocketReceivedOpenGame(): Array<string> {
        return this.ccvReceivedOpenGame;
    }

    get getSocketReceivedCloseGame(): Array<string> {
        return this.ccvReceivedCloseGame;
    }

    get getSocketReceivedBalanceChanged(): Array<string> {
        return this.ccvReceivedBalanceChanged;
    }

    get getSocketReceivedJackpotInfos(): Array<string> {
        return this.ccvReceivedJackpotInfos;
    }

    get getSocketReceivedTrendInfos(): Array<string> {
        return this.ccvReceivedTrendInfos;
    }

    @Mutation
    [Mutations.SET_SOCKET_RECEIVED_OPEN_GAME](prmPayload) {
        this.ccvReceivedOpenGame = prmPayload;
    }

    @Mutation
    [Mutations.SET_SOCKET_RECEIVED_CLOSE_GAME](prmPayload) {
        this.ccvReceivedCloseGame = prmPayload;
    }

    @Mutation
    [Mutations.SET_SOCKET_RECEIVED_BALANCE_CHANGED](prmPayload) {
        this.ccvReceivedBalanceChanged = prmPayload;
    }

    @Mutation
    [Mutations.SET_SOCKET_RECEIVED_JACKPOT_INFOS](prmPayload) {
        this.ccvReceivedJackpotInfos = prmPayload;
    }

    @Mutation
    [Mutations.SET_SOCKET_RECEIVED_TREND_INFOS](prmPayload) {
        this.ccvReceivedTrendInfos = prmPayload;
    }

    @Action
    [Actions.SOCKET_RECEIVED_OPEN_GAME](payload) {
        this.context.commit(Mutations.SET_SOCKET_RECEIVED_OPEN_GAME, payload);
    }

    @Action
    [Actions.SOCKET_RECEIVED_CLOSE_GAME](payload) {
        this.context.commit(Mutations.SET_SOCKET_RECEIVED_CLOSE_GAME, payload);
    }

    @Action
    [Actions.SOCKET_RECEIVED_BALANCE_CHANGED](payload) {
        this.context.commit(Mutations.SET_SOCKET_RECEIVED_BALANCE_CHANGED, payload);
    }

    @Action
    [Actions.SOCKET_RECEIVED_JACKPOT_INFOS](payload) {
        this.context.commit(Mutations.SET_SOCKET_RECEIVED_JACKPOT_INFOS, payload);
    }

    @Action
    [Actions.SOCKET_RECEIVED_TREND_INFOS](payload) {
        this.context.commit(Mutations.SET_SOCKET_RECEIVED_TREND_INFOS, payload);
    }

}