import Swal from "sweetalert2/dist/sweetalert2.js";
import { SHA256, enc, PBKDF2} from 'crypto-js';

export const libTools = {
    method: {
        swalError(prmConfirmButtonText, prmText){
            Swal.fire({
                icon: "error", buttonsStyling: false, customClass: { confirmButton: "btn fw-bold btn-light-danger", },
                text: prmText,
                confirmButtonText: prmConfirmButtonText,
            }).then(function () {
                //
            });
        },

        swalSuccess(prmConfirmButtonText, prmText){
            Swal.fire({
                icon: "success", buttonsStyling: false, customClass: { confirmButton: "btn fw-bold btn-light-danger", },
                text: prmText,
                confirmButtonText: prmConfirmButtonText,
            }).then(function () {
                //
            });
        },

        getHashedPass(prmValue) {
            const rv = PBKDF2(prmValue, process.env.VUE_APP_PASSWORD_SALT, { keySize: 8, iterations: 1000  }).toString(enc.Hex);
            //console.log("getHashedPass "+process.env.VUE_APP_PASSWORD_SALT+" "+prmValue+" rv:  "+rv);
            return rv;
        },

        getLogoMain(prmType) {
            let rv = "media/logos/custom/"+process.env.VUE_APP_CUST_MAINLOGO_DARK+".png";
            if (prmType==="WHITE") {
                rv = "media/logos/custom/"+process.env.VUE_APP_CUST_MAINLOGO_WHITE+".png";
            }
            //console.log("getLogoMain "+prmType+" rv:  "+rv);
            return rv;
        },

        getLogoFavIcon() {
            const rv = window.location.protocol+"//"+ window.location.host+"/"+"media/logos/custom/"+process.env.VUE_APP_CUST_MAINLOGO_FAVICON;
            //console.log("getLogoFavIcon  rv:  "+rv);
            return rv;
        },

        getPageTitle() {
            const rv = ""+process.env.VUE_APP_PAGE_TITLE;
            //console.log("getPageTitle  rv:  "+rv);
            return rv;
        },

        hasTurkishChar(prmText) {
            if (
                prmText.includes("Ü") || prmText.includes("ü") ||
                prmText.includes("Ğ") || prmText.includes("ğ") ||
                prmText.includes("İ") || prmText.includes("ı") ||
                prmText.includes("Ş") || prmText.includes("ş") ||
                prmText.includes("Ö") || prmText.includes("ö") ||
                prmText.includes("Ç") || prmText.includes("ç")
            ) {
                return true;
            }

            return false;
        },

        localeStA(prmValue, prmDigit) {
            return Number(prmValue).toLocaleString(undefined, { minimumFractionDigits: prmDigit,  maximumFractionDigits: prmDigit})
        },

        getCurrentSign(prmCur) {
            if (prmCur === "TRY") return "₺";
            if (prmCur === "USD") return "$";
            return "₺";
        },

        getDayOfWeek(prmDate) {
            let vvDayOfWeek = prmDate.getDay()	;
            if (vvDayOfWeek===0) vvDayOfWeek = 7;
            return vvDayOfWeek;
        },

    }
}

class Lib_Tools {

}

export default Lib_Tools;