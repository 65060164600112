/* eslint-disable */

var glbChartProcessor = null;
var getFuncClickTabSymbol = null;
var getFuncGetTickers = null;
var getFuncAddTicker = null;
var getFuncSetCloseTime = null;
var getFuncSetChartType = null;
var getFuncSwitchSection = null;
var getFuncSwitchTxtInfo = null;
var getFuncFillChartDimensions = null;
var getFuncDestroyChart = null;

var expComp = {
    getChartProcessor() {
        return glbChartProcessor;
    },
    getFuncClickTabSymbol() {
        return getFuncClickTabSymbol;
    },
    getFuncGetTickers() {
        return getFuncGetTickers;
    },
    getFuncAddTicker() {
        return getFuncAddTicker;
    },
    getFuncSetCloseTime() {
        return getFuncSetCloseTime;
    },
    getFuncSetChartType() {
        return getFuncSetChartType;
    },
    getFuncSwitchSection() {
        return getFuncSwitchSection;
    },
    getFuncSwitchTxtInfo() {
        return getFuncSwitchTxtInfo;
    },
    getFuncFillChartDimensions() {
        return getFuncFillChartDimensions;
    },
    getFuncDestroyChart() {
        return getFuncDestroyChart;
    },

};

export default expComp;

$(document).ready(async () => {
        /* const priceSocketHandler = new PriceSocketHandler(
          "https://staging-provider-engine.devoptions.app/",
          "/websocket",
          "client_01",
          "BTC-USDT"
        );
        priceSocketHandler.connect(); */

        window.startChart = async (prmLinkWss, prmDuration, prmSymbol, prmArrSymbols, prmArrTickers) => {
            //console.log("startChart START prmDuration: " + prmDuration + " prmSymbol: " + prmSymbol);
            //console.log("startChart START prmArrSymbols.length: " + prmArrSymbols.length);
            //console.log("startChart START prmArrTickers.length: " + prmArrTickers.length);
            //console.log("startChart prmArrSymbols: " + JSON.stringify(prmArrSymbols));

            await new Promise((r) => setTimeout(r, 2000));

            const candleBtn = document.querySelector("#candle-btn");
            const lineBtn = document.querySelector("#line-btn");
            const switchSectionBtn = document.querySelector("#switch-section-btn");

            const changeEthTickerBtn = document.querySelector("#change-eth-ticker-btn");
            const changeBtcTickerBtn = document.querySelector("#change-btc-ticker-btn");

            const upBtn = document.querySelector("#up-btn");
            const downBtn = document.querySelector("#down-btn");
            const equalBtn = document.querySelector("#equal-btn");

            const darkThemeBtn = document.querySelector("#dark-theme-btn");
            const orageThemeBtn = document.querySelector("#orange-theme-btn");

            try {
                var arrSymbols = prmArrSymbols;
                var TICKERS = prmArrTickers;

                TICKERS = setPropsForAllTickers();
                //console.log("startChart VTA_A_TICKERS TICKERS: " + JSON.stringify(TICKERS));
                console.log("startChart VTA_A_TICKERS VUE_APP_CHART_LINK_WSS: " + prmLinkWss);

                //https://engine.pesatr.app/
                const chartProcessor = new ChartProcessor(
                    //"wss://staging-symbol.devoptions.app/symbolticker",
                    prmLinkWss,
                    {
                        path: "/websocket",
                        token: "client_01",
                    },
                    "https://i.ibb.co/hVtbxQ1/fill-white-flag.png",
                    Number(prmDuration),
                    3000,
                    8,
                    500,
                    "chart-bullet",
                    TICKERS,
                    TICKERS[0],

                    {
                        DARK: {
                            EMPTY_FLAG_URL: "https://i.ibb.co/ZMvsyQv/empty-flag.png",
                            CHART_BACKGROUND_IMAGE_URL: "https://www.linkpicture.com/q/chart-background.jpeg",  //https://www.linkpicture.com/q/chart-background.jpeg
                            CHART_BACKGROUND_COLOR: ["rgb(22, 23, 38)", "rgb(14, 14, 14)"],
                            VALUE_BACKGROUND_COLOR: ["rgb(22, 23, 38)", "rgb(14, 14, 14)"],
                            DATE_BACKGROUND_COLOR: ["rgba(22, 23, 38, 0.4)"],
                            LINE_STROKE_COLOR: "rgb(68, 104, 146)",
                            HOR_PRICE_LINE_COLOR: "rgb(65, 101, 145)",
                            VERT_DATE_LINE_COLOR: "rgb(65, 101, 145)",
                            DOT_COLOR: "rgb(0, 182, 249)",
                            FINISH_INFO_COLOR: "rgb(68, 104, 146)",
                            TRANSACTION_BET_TEXT_COLOR: "rgb(255, 255, 255)",
                            TRANSACTION_BET_TEXT_GREEN_BACKGROUND_COLOR: "rgb(68, 148, 67)",
                            TRANSACTION_BET_TEXT_RED_BACKGROUND_COLOR: "rgb(220, 55, 27)",
                            TRANSACTION_BET_TEXT_YELLOW_BACKGROUND_COLOR: "rgb(255, 193, 7)",
                            TRANSACTION_GREEN: "rgb(58, 181, 75)",
                            TRANSACTION_RED: "rgb(243, 56, 44)",
                            TRANSACTION_YELLOW: "rgb(255, 235, 0)",
                            TRANSACTION_WINNER_EMOJI: "✅",
                            TRANSACTION_LOSER_EMOJI: "❌",
                            TRANSACTION_TIE_EMOJI: "🟡",
                            CROSS_HAIR_TEXT_COLOR: "rgb(255, 255, 255)",
                            CROSS_HAIR_FILL_COLOR: "rgb(143, 165, 191)",
                            MAX_MIN_TEXT_COLOR: "rgba(116, 134, 157,0.8)",
                            MAX_MIN_BACKGROUND_COLOR: "rgba(44, 46, 70,0.6)",
                            MOUNTAIN_TRANSPARENT_COLOR: "rgba(0,0,0,0)",
                            MOUNTAIN_AREA_COLOR: "rgba(43, 56, 80,0.3)",
                            LINE_UP_AREA_HOVER_COLOR: "rgba(58, 181, 75,0.1)",
                            LINE_DOWN_AREA_HOVER_COLOR: "rgba(243, 56, 44,0.1)",
                            TOOLTIP_TEXT_COLOR: "rgb(255, 255, 255)",
                            TOOLTIP_BORDER_COLOR: "rgb(232, 135, 80)",
                            TOOLTIP_BACKGROUND_COLOR: "rgb(44, 44, 44)",
                            CANDLE_UP_COLOR: "rgb(69, 183, 52)",
                            CANDLE_DOWN_COLOR: "rgb(255, 62, 31)",
                            DOT_COLOR_CLASS: "blue",
                            WATERMARK_TEXT_COLOR: "rgba(255, 255, 255,0.1)",
                            DATE_SCALE_TEXT_COLOR: "rgba(112, 113, 119)",
                            VALUE_SCALE_TEXT_COLOR: "rgba(112, 113, 119)",
                        },
                        ORANGE: {
                            EMPTY_FLAG_URL: "https://i.ibb.co/RHb4yXF/empty-flag-orange.png",
                            CHART_BACKGROUND_IMAGE_URL: "https://i.ibb.co/Bq8tstw/theme1-bg.jpg",  //https://gcdnb.pbrd.co/images/TjuETpBrvR68.png?o=1
                            LINE_STROKE_COLOR: "rgb(120, 120, 120)",  //rgb(255, 110, 49)
                            HOR_PRICE_LINE_COLOR: "rgb(255, 172, 28)",
                            VERT_DATE_LINE_COLOR: "rgb(255, 172, 28)",
                            FINISH_INFO_COLOR: "rgb(255, 192, 0)",
                            TRANSACTION_BET_TEXT_COLOR: "rgb(255, 255, 255)",
                            TRANSACTION_BET_TEXT_GREEN_BACKGROUND_COLOR: "rgb(68, 148, 67)",
                            TRANSACTION_BET_TEXT_RED_BACKGROUND_COLOR: "rgb(220, 55, 27)",
                            TRANSACTION_BET_TEXT_YELLOW_BACKGROUND_COLOR: "rgb(255, 193, 7)",
                            TRANSACTION_GREEN: "rgb(58, 181, 75)",
                            TRANSACTION_RED: "rgb(243, 56, 44)",
                            TRANSACTION_YELLOW: "rgb(255, 235, 0)",
                            TRANSACTION_WINNER_EMOJI: "✅",
                            TRANSACTION_LOSER_EMOJI: "❌",
                            TRANSACTION_TIE_EMOJI: "🟡",
                            CROSS_HAIR_TEXT_COLOR: "rgb(255, 255, 255)",
                            CROSS_HAIR_FILL_COLOR: "rgba(255, 172, 28,0.4)",     //
                            MAX_MIN_TEXT_COLOR: "rgba(255, 255, 255,0.9)",
                            MAX_MIN_BACKGROUND_COLOR: "rgba(255, 172, 28,0.4)",
                            MOUNTAIN_TRANSPARENT_COLOR: "rgba(0,0,0,0)",   //rgba(0,0,0,0)
                            MOUNTAIN_AREA_COLOR: "rgba(255, 110, 49,0.15)",
                            LINE_UP_AREA_HOVER_COLOR: "rgba(58, 181, 75,0.1)",
                            LINE_DOWN_AREA_HOVER_COLOR: "rgba(243, 56, 44,0.1)",
                            TOOLTIP_TEXT_COLOR: "rgb(255, 255, 255)",
                            TOOLTIP_BORDER_COLOR: "rgb(232, 135, 80)",
                            TOOLTIP_BACKGROUND_COLOR: "rgb(44, 44, 44)",
                            CANDLE_UP_COLOR: "rgb(10, 250, 10)",  //rgb(199, 234, 70)
                            CANDLE_DOWN_COLOR: "rgb(255, 3, 62)",
                            DOT_COLOR_CLASS: "orange",
                            WATERMARK_TEXT_COLOR: "rgba(200, 200, 200,0.2)",      //rgba(255, 255, 255,0.7)
                            DATE_SCALE_TEXT_COLOR: "rgba(255, 255, 255)",
                            VALUE_SCALE_TEXT_COLOR: "rgba(255, 255, 255)",
                        },
                    },
                    document
                        .querySelector("#chart-wrapper")
                        ?.getBoundingClientRect()?.height,
                    document
                        .querySelector("#chart-wrapper")
                        ?.getBoundingClientRect()?.width,
                    "ORANGE",
                    "down-btn",
                    "up-btn",
                    "equal-btn",
                    "chart-button-panel",
                    "scroll-to-end",
                    "stop-focus-chart-btn",
                    [],
                    "chart-wrapper",
                    "page-header",
                    150,                 // 150    - 15 * 60 * 12
                    "mountain",          // mountain - candle
                    1,                   // Candle Period (15)
                    "m",                 // Candle Interval (m) (s)
                    false,               // Default Section Area Show or not
                    false,               // Transaction bilgilerini göster / gösterme
                    callBackChart
                );

                //console.log("startChart VTA_BEFORE_START_CHART");

                await chartProcessor.startChart();

                //console.log("startChart VTA_AFTER_START_CHART");

                function callBackChart(prmParams) {
                    //console.log("callBackChart START");
                }

                switchSectionBtn.addEventListener("click", () => {
                    chartProcessor.switchSection();
                    //chartProcessor.switchSection(true);
                });

                candleBtn.addEventListener("click", () => {
                    /*
                    chartProcessor.changeChartType({
                        type: "candle",
                        candlePeriod: 30,
                        timeScale: 30 * 60 * 12,
                    });
                     */
                });

                lineBtn.addEventListener("click", () => {
                    /*
                    chartProcessor.changeChartType({
                        type: "mountain",
                        timeScale: 100,
                    });
                     */
                });

                darkThemeBtn.addEventListener("click", () => {
                    chartProcessor.changeTheme("DARK");
                });

                orageThemeBtn.addEventListener("click", () => {
                    chartProcessor.changeTheme("ORANGE");
                });

                glbChartProcessor = chartProcessor;

                getFuncFillChartDimensions = function () {
                    //console.log("getFuncFillChartDimensions START ");
                    chartProcessor.fillChartDimensions();
                };

                getFuncClickTabSymbol = function (prmSymbol) {
                    //console.log("getFuncClickTabSymbol START prmSymbol: " + prmSymbol);
                    setCurrentTicker(prmSymbol);
                };

                getFuncGetTickers = function () {
                    //console.log("getFuncGetTickers START ");
                    return TICKERS;
                };

                getFuncAddTicker = function (prmSymbol) {
                    //console.log("getFuncAddTicker START prmSymbol: " + prmSymbol);
                    addTicker(prmSymbol);
                };

                getFuncSetCloseTime = function (prmDuration) {
                    //console.log("ChartMainJs  getFuncSetCloseTime " + prmDuration);
                    setCloseTime(prmDuration);
                };

                getFuncSetChartType = function (prmChartType, prmTimeScale, prmCandlePeriod, prmCandleInterval) {
                    console.log("ChartMainJs  getFuncSetChartType prmChartType: " + prmChartType + " prmTimeScale: " + prmTimeScale + " prmCandlePeriod: " + prmCandlePeriod+" prmCandleInterval: "+prmCandleInterval);
                    chartProcessor.changeChartType({
                        type: prmChartType,
                        candlePeriod: Number(prmCandlePeriod),
                        candleInterval: prmCandleInterval,  //-s -m
                        timeScale: Number(prmTimeScale),

                    });
                };

                getFuncSwitchSection = function () {
                    chartProcessor.switchSection();
                    //chartProcessor.switchSection(true);
                }

                getFuncSwitchTxtInfo = function () {
                    chartProcessor.switchTxInfo();
                    //chartProcessor.switchTxInfo(true);
                }

                getFuncDestroyChart = function () {
                    chartProcessor.destroyChart();
                }

                function getTicker(prmSymbol) {
                    console.log("getTicker START len: " + TICKERS.length);
                    for (let i = 0; i < TICKERS.length; i++) {
                        let tmpItem = TICKERS[i];
                        //console.log("getTicker each wsTickerName: " + tmpItem.wsTickerName);
                        if (tmpItem.wsTickerName === prmSymbol.replace("/", "/")) {
                            return tmpItem;
                        }
                    }
                    return null;
                }

                function addTicker(prmSymbol) {
                    console.log("addTicker START " + prmSymbol);
                    let tmpTicker = getTicker(prmSymbol);

                    //console.log("addTicker START " + prmSymbol + " tmpTicker: " + tmpTicker);

                    if (tmpTicker === null) {
                        let newTicker = {
                            chartInfo: {symbol: prmSymbol, company: "", tickSize: 0.01,},
                            valueFloatFixed: 0,
                            wsTickerName: prmSymbol.replace("/", "/"),
                        };
                        TICKERS.push(setPropOfTicker(newTicker));
                        //printTickers();
                    }

                    TICKERS = setPropsForAllTickers();
                }

                function printTickers() {
                    console.log("printTickers START");
                    for (let i = 0; i < TICKERS.length; i++) {
                        let tmpItem = TICKERS[i];
                        console.log(
                            "printTickers each wsTickerName: " + tmpItem.wsTickerName
                        );
                    }
                }

                function setPropsForAllTickers() {
                    //console.log("setPropsForAllTickers START");
                    for (let i = 0; i < TICKERS.length; i++) {
                        TICKERS[i] = setPropOfTicker(TICKERS[i]);
                    }
                    return TICKERS;
                }

                function setPropOfTicker(prmTickerItem) {
                    let tmpSymbolItem = getSymbolItem(prmTickerItem.wsTickerName);
                    //console.log("setPropOfTicker EACH wsTickerName: " + prmTickerItem.wsTickerName);
                    if (tmpSymbolItem !== null) {
                        prmTickerItem.valueFloatFixed = tmpSymbolItem.Decimals;
                        //console.log("setPropOfTicker Changed wsTickerName: " + prmTickerItem.wsTickerName + " " + tmpSymbolItem.Decimals);
                    }
                    return prmTickerItem;
                }

                function setCurrentTicker(prmSymbol) {
                    console.log("setCurrentTicker START " + prmSymbol);
                    let tmpTicker = getTicker(prmSymbol);
                    if (tmpTicker !== null) {
                        if (chartProcessor.currentPairInfo.wsTickerName !== tmpTicker.wsTickerName) {
                            chartProcessor.changeSymbol(tmpTicker);
                            /*
                            chartProcessor.saveState();
                            chartProcessor.currentPairInfo = tmpTicker;
                            chartProcessor.currentWS = chartProcessor.switchTicker( chartProcessor.currentPairInfo );
                            */
                        }
                    } else {
                        console.log("setCurrentTicker tmpTicker is NULL");
                    }
                }

                function getSymbolItem(prmSymbol) {
                    for (let i = 0; i < arrSymbols.length; i++) {
                        let tmpItem = arrSymbols[i];
                        //console.log( "getSymbolItem each Symbols: " + tmpItem.Symbols+" Decimals: "+tmpItem.Decimals );
                        if (tmpItem.Symbols === prmSymbol) {
                            return tmpItem;
                        }
                    }
                    return null;
                }

                function setCloseTime(prmDuration) {
                    //chartProcessor.betDuration = Number(prmDuration);
                    chartProcessor.changeDuration(Number(prmDuration));
                    console.log("ChartMain setCloseTime " + chartProcessor.betDuration);
                }

                /*
                changeBtcTickerBtn.addEventListener("click", () => {
                    if (
                        chartProcessor.currentPairInfo.wsTickerName !==
                        TICKERS[0].wsTickerName
                    ) {
                        chartProcessor.saveState();
                        chartProcessor.currentPairInfo = TICKERS[0];
                        chartProcessor.currentWS = chartProcessor.switchTicker(
                            chartProcessor.currentPairInfo
                        );
                    }
                });

                changeEthTickerBtn.addEventListener("click", () => {
                    if (
                        chartProcessor.currentPairInfo.wsTickerName !==
                        TICKERS[1].wsTickerName
                    ) {
                        chartProcessor.saveState();
                        chartProcessor.currentPairInfo = TICKERS[1];
                        chartProcessor.currentWS = chartProcessor.switchTicker(
                            chartProcessor.currentPairInfo
                        );
                    }
                });
                 */
            } catch
                (err)
            {
                console.log("Error: " + err?.message || err?.data || err);
            }
        }
        ;
    }
)
;
