import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import AppCore from "@/core/appcore/AppCore";
import store from "@/store";


interface myvar {
    data: Array<string>;
}

export interface GameListsInfo {
    myListA: myvar;
    myListB: myvar;
    myGameHistoryList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class GameListModule extends VuexModule implements GameListsInfo {

    errors = [];
    myListA = {} as myvar;
    myListB = {} as myvar;
    myGameHistoryList = {} as myvar;
    myDetails = [];

    get gameListsListA(): myvar {
        return this.myListA;
    }

    get gameListsListB(): myvar {
        return this.myListB;
    }

    get gameListsHistoryList(): myvar {
        return this.myGameHistoryList;
    }

    get gameListsDetails(): Array<string> {
        return this.myDetails;
    }

    get gameListsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_GAME_LISTS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_GAME_LISTS_LISTA](payload) {
        this.myListA = payload;
    }

    @Mutation
    [Mutations.SET_GAME_LISTS_LISTB](payload) {
        this.myListB = payload;
    }

    @Mutation
    [Mutations.SET_GAME_LISTS_HISTORY_LIST](payload) {
        this.myGameHistoryList = payload;
    }

    @Mutation
    [Mutations.SET_GAME_LISTS_ADD](prmItem) {
        //this.myList.data.push(prmItem);

    }

    @Mutation
    [Mutations.SET_GAME_LISTS_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Action
    [Actions.GAME_LISTS_LIST](payload) {
        //console.log("GameListModule GAME_LISTS_LIST START payload: "+JSON.stringify(payload));
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/games/list", payload)
                .then(({ data }) => {

                    if (data.data===undefined)  {
                        payload.data = [];
                    } else {
                        payload.data = data.data;
                    }

                    if (data.status==200) {
                        if (payload.CallFrom === "ChartDrawed" || payload.CallFrom === "ProcessDrawer") {
                            AppCore.prsCore_Game.SetDefaultLocalDateAllData(payload);
                            if (payload.Status==="WAIT" || payload.Status==="WAIT2") {
                                AppCore.prsCore_Game.ccvIsGameListDrawedFirst = false;
                                this.context.commit(Mutations.SET_GAME_LISTS_LISTA, payload);
                                if (payload.CallFrom === "ChartDrawed") { store.dispatch(Actions.GAME_ISLOADING_CHART, false);}
                            } else {
                                this.context.commit(Mutations.SET_GAME_LISTS_LISTB, payload);
                            }
                            AppCore.prsCore_Game.setIsOpenGameEnabled(true);

                        }
                        if (payload.CallFrom === "ProcessHistory") {
                            this.context.commit(Mutations.SET_GAME_LISTS_HISTORY_LIST, payload);
                        }
                    } else {
                        if (data.status==404) {

                            AppCore.prsCore_Game.processLogout();
                            store.dispatch(Actions.LOGOUT).then(() => AppCore.prsCore_Game.router.push({ name: AppCore.prsCore_Game.getSignInUserPath("CF_NAVSIGIN_GAME_LIST_LIST") }));
                            //AppCore.prsCore_Game.router.go(0);

                            //AppCore.prsCore_Game.navigateToSignIn("CF_NAVSIGIN_GAME_LIST_LIST");
                            //AppCore.prsCore_Game.processLogout();
                            //this.context.dispatch(Actions.LOGOUT, {});
                        }
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.GAME_LISTS_ADD](prmItem) {
        prmItem.StartDateLocal = new Date();
        //console.log("GameListModule GAME_LISTS_ADD prmItem: "+JSON.stringify(prmItem));
        return new Promise<void>((resolve, reject) => {

            const tmpListA = AppCore.prsCore_Game.gameListPrs_AddItem(this.myListA, prmItem);
            const tmpListB = AppCore.prsCore_Game.gameListPrs_AddItem(this.myListB, prmItem);

            this.context.commit(Mutations.SET_GAME_LISTS_LISTA, tmpListA);
            this.context.commit(Mutations.SET_GAME_LISTS_LISTB, tmpListB);

            resolve();

        });

    }

    @Action
    [Actions.GAME_LISTS_SETITEM](prmItem) {
        //console.log("GameListModule GAME_LISTS_SETITEM prmItem: "+JSON.stringify(prmItem));
        return new Promise<void>((resolve, reject) => {
            const tmpListA = AppCore.prsCore_Game.gameListPrs_SetItem(this.myListA, prmItem, "SetStatus");
            const tmpListB = AppCore.prsCore_Game.gameListPrs_SetItem(this.myListB, prmItem, "SetStatus");
            this.context.commit(Mutations.SET_GAME_LISTS_LISTA, tmpListA);
            this.context.commit(Mutations.SET_GAME_LISTS_LISTB, tmpListB);
            resolve();
        });
    }

    @Action
    [Actions.GAME_LISTS_SETITEM_FINISHRATE](prmItem) {
        //console.log("GameListModule GAME_LISTS_SETITEM_FINISHRATE prmItem: "+JSON.stringify(prmItem));
        return new Promise<void>((resolve, reject) => {
            const tmpListA = AppCore.prsCore_Game.gameListPrs_SetItem(this.myListA, prmItem, "SetFinishRate");
            const tmpListB = AppCore.prsCore_Game.gameListPrs_SetItem(this.myListB, prmItem, "SetFinishRate");
            this.context.commit(Mutations.SET_GAME_LISTS_LISTA, tmpListA);
            this.context.commit(Mutations.SET_GAME_LISTS_LISTB, tmpListB);
            resolve();
        });
    }

    @Action
    [Actions.GAME_LISTS_SETITEM_CANCEL](prmItem) {
        //console.log("GameListModule GAME_LISTS_SETITEM_CANCEL prmItem: "+JSON.stringify(prmItem));
        return new Promise<void>((resolve, reject) => {
            const tmpListA = AppCore.prsCore_Game.gameListPrs_SetItem(this.myListA, prmItem, "SetCancel");
            const tmpListB = AppCore.prsCore_Game.gameListPrs_SetItem(this.myListB, prmItem, "SetCancel");
            this.context.commit(Mutations.SET_GAME_LISTS_LISTA, tmpListA);
            this.context.commit(Mutations.SET_GAME_LISTS_LISTB, tmpListB);
            resolve();
        });
    }



}