import { App } from "vue";

import {dateConvert} from "@/core/appcore/libs/Lib_DateConvert";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

import PrsCore_Game from "@/core/appcore/prscores/games/PrsCore_Game";


class AppCore {
    
    public static vueInstance: App;
    
    public static prsCore_Game: PrsCore_Game;    

    public static init(app: App<Element>) {        
        AppCore.vueInstance = app;            
        AppCore.prsCore_Game = new PrsCore_Game();

        console.log("=== AppCore ccvVer: "+AppCore.prsCore_Game.ccvVer+" "+process.env.VUE_APP_ACODE);
    }   

}

export default AppCore;