import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface MyModuleInterface {
    myValue1;
}

@Module
export default class MyModule extends VuexModule implements MyModuleInterface {

    myValue1;
    myPayload1;


    @Mutation
    [Mutations.SET_MYVALUE](payload): void {
        //this.myValue1++;
        //console.log("SET_MYVALUE START ["+this.myValue1+"] payload:  "+payload);
        this.myPayload1 = payload;    
    }

    @Action
    [Actions.SET_MYVALUE_ACTION](payload) {
        this.myValue1++;
        console.log("SET_MYVALUE_ACTION START  1.3 ["+this.myValue1+"] payload:  "+payload);
        this.context.commit(Mutations.SET_MYVALUE, "Hello from Action B");
    }


}