import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import AppCore from "@/core/appcore/AppCore";

interface myvar {
    data: Array<string>;
}

export interface BalancesInfo {
    myList: myvar;
    errors: Array<string>;
}

@Module
export default class BalanceModule extends VuexModule implements BalancesInfo {
    errors = [];
    myList = {} as myvar;

    get getBalanceList(): myvar {
        return this.myList;
    }

    get getBalancesErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_BALANCES_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_BALANCES_LIST](payload) {
        this.myList = payload;

        //console.log("SET_BALANCES_LIST payload.data: "+JSON.stringify(payload.data));

        AppCore.prsCore_Game.setBalance(payload.data.Balance);
        AppCore.prsCore_Game.setBalanceDemo(payload.data.DemoBalance);
    }

    @Action
    [Actions.BALANCES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/balance", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_BALANCES_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

}