import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";
import AppCore from "@/core/appcore/AppCore";

interface myvar {
    data: Array<string>;
}

export interface FavoriteSymbolsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class FavoriteSymbolModule extends VuexModule implements FavoriteSymbolsInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get favoriteSymbolsList(): myvar {
        return this.myList;
    }

    get favoriteSymbolsDetails(): Array<string> {
        return this.myDetails;
    }

    get favoriteSymbolsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_FAVORITE_SYMBOLS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_FAVORITE_SYMBOLS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_FAVORITE_SYMBOLS_DETAILS](payload) {
        this.myDetails = payload;
    }
    
    @Action
    [Actions.FAVORITE_SYMBOLS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {            
            ApiService.setHeader();
            AppCore.prsCore_Game.initSymbol("");
            ApiService.post("/favorite/get", payload)
                .then(({ data }) => {
                    payload.data = data;
                    console.log("FAVORITE_SYMBOLS_LIST payload: "+JSON.stringify(payload));
                    this.context.commit(Mutations.SET_FAVORITE_SYMBOLS_LIST, payload);

                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }
    
    @Action
    [Actions.FAVORITE_SYMBOLS_INSERT](prmPayload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/favorite/add", prmPayload)
                .then(({ data }) => {
                    if(data.status == 200) {
                        //const vvPayLoad = { }
                        //store.dispatch(Actions.FAVORITE_SYMBOLS_LIST, vvPayLoad);
                        AppCore.prsCore_Game.setSymbolToFavorite(prmPayload.Symbols, true);
                        resolve();
                    } else {
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_FAVORITE_SYMBOLS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_FAVORITE_SYMBOLS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.FAVORITE_SYMBOLS_DELETE](prmPayload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/favorite/remove", prmPayload)
                .then(({ data }) => {
                    if(data.status == 200) {
                        //const vvPayLoad = {}
                        //store.dispatch(Actions.FAVORITE_SYMBOLS_LIST, vvPayLoad);
                        AppCore.prsCore_Game.setSymbolToFavorite(prmPayload.Symbols, false);
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_FAVORITE_SYMBOLS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_FAVORITE_SYMBOLS_ERROR, myError);
                    reject();
                });
        });
    }


}