import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { useRouter } from "vue-router";
import AppCore from "@/core/appcore/AppCore";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    verifyUserResult : unknown;
    registerUserResult : unknown;
    loginResult : unknown;
    forgotpasswordResult : unknown;
    resetpasswordResult : unknown;
    processLogout: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();
    verifyUserResult = {};
    registerUserResult = {};
    loginResult = {};
    forgotpasswordResult = {};
    resetpasswordResult = {};
    processLogout = "";

    router = useRouter();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    get getVerifyUserResult () {
        return this.verifyUserResult;
    }

    get getRegisterUserResult () {
        return this.registerUserResult;
    }

    get getLoginResult () {
        return this.loginResult;
    }

    get getForgotPasswordResult() {
        return this.forgotpasswordResult;
    }

    get getResetPasswordResult() {
        return this.resetpasswordResult;
    }

    get getProcessLogout () {
        return this.processLogout;
    }

    @Mutation
    [Mutations.SET_ERROR](prmErrors) {
        //this.errors = { ...error };
        console.log("SET_ERROR prmErrors: "+JSON.stringify(prmErrors));
        this.errors = prmErrors;
    }

    @Mutation
    [Mutations.SET_LOGIN_RESULT](prmValue) {
        //console.log("SET_LOGIN_RESULT prmValue: "+JSON.stringify(prmValue));
        this.loginResult = prmValue;
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = {};

        //console.log("SET_AUTH user: "+user);
        //console.log("SET_AUTH Logincode: "+user.Logincode);
        //console.log("SET_AUTH Username: "+user.Username);

        JwtService.saveToken(user.Logincode);
        JwtService.saveUserName(user.Username);
        JwtService.saveUserInfo(user);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.SET_VERIFY_USER_RESULT](_verifyUserResult) {
        this.verifyUserResult = _verifyUserResult;
    }

    @Mutation
    [Mutations.SET_REGISTER_USER_RESULT](prmResult) {
        this.registerUserResult = prmResult;
    }

    @Mutation
    [Mutations.SET_FORGOT_PASSWORD_RESULT](prmResult) {
        this.forgotpasswordResult = prmResult;
    }

    @Mutation
    [Mutations.SET_RESET_PASSWORD_RESULT](prmResult) {
        this.resetpasswordResult = prmResult;
    }

    @Mutation
    [Mutations.SET_PROCESS_LOGOUT](prmValue) {
        this.processLogout = prmValue;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
        JwtService.destroyUserName();
        JwtService.destroyUserInfo();
        //console.log("Mutations.PURGE_AUTH END");
    }

    @Action
    [Actions.LOGIN](credentials) {
        ApiService.setHeader();

        return ApiService.post("signin", credentials)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_LOGIN_RESULT, data);
                if (data.status == 200) {
                    this.context.commit(Mutations.SET_AUTH, data.data);
                } else {
                    this.context.commit(Mutations.SET_ERROR, data.messages);
                }

            }) .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        ApiService.setHeader();
        return ApiService.post("register", credentials)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_REGISTER_USER_RESULT, data);
                //console.log("AUTH REGISTER data: "+JSON.stringify(data));
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.REGISTER_SET_USER_VALUE](payload) {
        this.context.commit(Mutations.SET_REGISTER_USER_RESULT, payload);
    }

    @Action
    [Actions.PROCESS_LOGOUT](payload) {
        this.context.commit(Mutations.SET_PROCESS_LOGOUT, payload);
    }

    @Action
    [Actions.VERIFY_USER](credentials) {
        ApiService.setHeader();
        return ApiService.post("verifyUser", credentials)
            .then(({ data }) => {
                //console.log("AUTH VERIFY_USER data: "+JSON.stringify(data));
                this.context.commit(Mutations.SET_VERIFY_USER_RESULT, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        ApiService.setHeader();
        return ApiService.post("forgotPassword", payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_FORGOT_PASSWORD_RESULT, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.RESET_PASSWORD](payload) {
        ApiService.setHeader();
        return ApiService.post("resetPassword", payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_RESET_PASSWORD_RESULT, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            //console.log("Verify Token START");
            ApiService.post("verifyToken", payload)
                .then(({ data }) => {
                    //console.log("Verify Token V1 status: "+data.status);
                    if (data.status!=200) {
                        this.context.commit(Mutations.PURGE_AUTH);
                        AppCore.prsCore_Game.navigateToSignIn("CF_NAVSIGIN_AUTH_VERIFY_AUTH");
                    }
                })
                .catch(({ response }) => {
                    //console.log("Verify Token V2");
                    this.context.commit(Mutations.SET_ERROR, "Login ERROR");
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        }
    }

}
