import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import AppCore from "@/core/appcore/AppCore";

interface myvar {
    data: Array<string>;
}

export interface DepositsInfo {
    myDeposits: myvar;
    myWithdraw: myvar;
    errors: Array<string>;
}

@Module
export default class DepositModule extends VuexModule implements DepositsInfo {
    errors = [];
    myDeposits = {} as myvar;
    myWithdraw = {} as myvar;

    get getDepositList(): myvar {
        return this.myDeposits;
    }

    get getWithdrawList(): myvar {
        return this.myWithdraw;
    }

    @Mutation
    [Mutations.SET_DEPOSITS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_DEPOSITS_LIST](prmValue) {
        this.myDeposits = prmValue;
    }

    @Mutation
    [Mutations.SET_WITHDRAWS_LIST](prmValue) {
        this.myWithdraw = prmValue;
    }

    @Action
    [Actions.DEPOSITS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/depositmethod", payload)
                .then(({ data }) => {

                    AppCore.prsCore_Game.chekcResult_For_LoginError(data);
                    payload.bonusStatus = data.bonusStatus;
                    payload.data = data.data;
                    if (payload.Type==="DEPOSIT") {
                        this.context.commit(Mutations.SET_DEPOSITS_LIST, payload);
                    }
                    if (payload.Type==="WITHDRAW") {
                        this.context.commit(Mutations.SET_WITHDRAWS_LIST, payload);
                    }

                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.DEPOSITS_INSERT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/deposit", payload)
                .then(({ data }) => {
                    if (data.status=== 201) {
                        console.log("DEPOSITS_INSERT messages: "+data.messages);
                        if (data.messages!==undefined) {
                            window.location= data.messages;
                        }
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.WITHDRAWS_INSERT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("/withdraw", payload)
                .then(({ data }) => {
                    console.log("WITHDRAWS_INSERT data: "+JSON.stringify(data))
                    AppCore.prsCore_Game.chekcResult_For_LoginError(data);
                    if (data.status=== 201) {
                        libTools.method.swalSuccess("Tamam", "İşleminiz başarıyla tamamlandı. Gerekli kontroller yapıldıktan sonra paranız hesabınıza aktarılacaktır");
                    }
                    if (data.status=== 406) {
                        if (data.code === "MSAPICD-082/1") {
                            libTools.method.swalError("Tamam", "İşlem İçin Bakiyeniz Yetersiz. Lütfen Tekrar Deneyiniz.");
                        }
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }


}