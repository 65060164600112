import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import MyModule from "@/store/modules/MyModule";
import SocketModule from "@/store/modules/SocketModule";
import RegisterUserModule from "@/store/modules/RegisterUserModule";
import GameModule from "@/store/modules/GameModule";
import SymbolModule from "@/store/modules/SymbolModule";
import FavoriteSymbolModule from "@/store/modules/FavoriteSymbolModule";
import GameListModule from "@/store/modules/GameListModule";
import DurationModule from "@/store/modules/DurationModule";
import BalanceModule from "@/store/modules/BalanceModule";
import DepositModule from "@/store/modules/DepositModule";
import BonusModule from "@/store/modules/BonusModule";
import BankModule from "@/store/modules/BankModule";
import ActionModule from "@/store/modules/ActionModule";
import JackpotModule from "@/store/modules/JackpotModule";
import ActionBonusModule from "@/store/modules/ActionBonusModule";
import AffiliateModule from "@/store/modules/AffiliateModule";


config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,    
    MyModule,
    RegisterUserModule,
    GameModule,
    SocketModule,
    SymbolModule,
    FavoriteSymbolModule,
    GameListModule,
    DurationModule,
    BalanceModule,
    DepositModule,
    BonusModule,
    BankModule,
    ActionModule,
    JackpotModule,
    ActionBonusModule,
    AffiliateModule,
  },
});

export default store;
