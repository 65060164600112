const ID_TOKEN_KEY = "id_token" as string;
const ID_USER_NAME = "id_user_name" as string;
const ID_USER_INFO = "id_user_info" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUserName = (): string | null => {
  return window.localStorage.getItem(ID_USER_NAME);
};

export const getUserInfo = (): string | null => {
  return window.localStorage.getItem(ID_USER_INFO);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveUserName = (username: string): void => {
  window.localStorage.setItem(ID_USER_NAME, username);
};

export const saveUserInfo = (userinfo: string): void => {
  window.localStorage.setItem(ID_USER_INFO, JSON.stringify(userinfo));
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyUserName = (): void => {
  window.localStorage.removeItem(ID_USER_NAME);
};

export const destroyUserInfo = (): void => {
  window.localStorage.removeItem(ID_USER_INFO);
};

export default { 
                getToken, saveToken, destroyToken
              , getUserName, saveUserName, destroyUserName 
              , getUserInfo, saveUserInfo, destroyUserInfo 
            };
