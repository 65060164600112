import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import mainjs from "@/local-js/chart-main.js";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import Swal from "sweetalert2/dist/sweetalert2.js";

class PrsCore_Game {

    public ccvVer = "2023-05-17 1.1.2.157";

    public ccvSymbol = "";
    public ccvDuration = 0;
    public ccvAmount = 0;
    public ccvWinRate = 0;
    public ccvMinAmount = 0;
    public ccvMaxAmount = 0;
    public ccvAvgAmount = 0;
    public ccvAccountType = "LIVE";  //DEMO
    public ccvBalance = 0;
    public ccvBalanceDemo = 0;
    public ccvOpenGameCount = 0;

    public arrSymbols = [];
    public theParity = null;   //Selected Parity

    public ccvFavoriteDurationRate = 0;
    public ccvFavoriteDifferentExist = false;

    public ccvLayoutMountedCount = 0;
    public ccvLogoutStatus = "none";

    public ccvChartType = "mountain";   //  - mountain       - candle
    public ccvChartTimeScale = 150;     //  - 100            - 15 * 60 * 12
    public ccvChartCandlePeriod = 1;
    public ccvChartCandleInterval = "m";

    public ccvMaxGameLimit = 20;

    public ccvIsLocal = false;
    public ccvIsInited = false;

    public ccvIsTraderActive = false;
    public ccvIsOpenGameEnabled = false;

    public ccvIsGameListSendAfterDraw = false;
    public ccvIsGameListDrawedFirst = false;
    public ccvIsSymbolTickerDifferent = false;  //if first ticker is not valid than setted to another

    public ccvSymbolTickerDifferentCurrentSymbolName = "";

    public ccvLastAddedSymbol = ""; //Added from SymbolList

    public ccvSecond = 0;

    public ccvViewPort = "";
    public ccvOrientation = "";

    public ws;
    private wsStatus = "None";

    private tmcWsTryConnect = 0;
    private tmcWsHeartBeat = 0;
    private tmcChartDrawed = 0;
    private tmcSentOpenGame = 0;

    public router;

    public funcPriceModal_ResetKeyBoard ;
    public funcUpEqualDown_ParityChanged ;
    public funcPriceModal_KeyDownIncDec ;
    public funcPasswordValidator_KeyDown ;

    public setIsTraderActive (prmIsTraderActive) {
        this.ccvIsTraderActive = prmIsTraderActive;
    }

    public initComp() {
        if (!this.ccvIsInited) {
            this.ccvIsInited =true;
            //console.log("PrsCore_Game initComp START ver: "+this.ccvVer)
            this.router = useRouter();
            setInterval(this.timerTick_OneSec, 1000, this);
        }
    }

    public incLayoutMountedCount () {
        this.ccvLayoutMountedCount++;
    }

    public initCompTrader() {
        this.initComp();
        //console.log("PrsCore_Game initCompTrader START ver: "+this.ccvVer)
        this.tmcChartDrawed = 0;
        this.ccvIsGameListSendAfterDraw = false;
        store.dispatch(Actions.SYMBOLS_LIST, { });
        this.setIsOpenGameEnabled(false);
        this.createSocket(false);
    }

    public initCompTraderAfterSymbols(prmArrSymbols) {  //V2
        //console.log("initCompTraderAfterSymbols START ccvIsSymbolTickerDifferent: "+ AppCore.prsCore_Game.ccvIsSymbolTickerDifferent+" ccvSymbolTickerDifferentCurrentSymbolName: "+AppCore.prsCore_Game.ccvSymbolTickerDifferentCurrentSymbolName );
        if (this.ccvSymbol==="") {
            this.getTickersFromSymbols(true);
            this.getDurationListFromSymbols();
        }
    }

    private getTickersFromSymbols(prmIsInitByFirst) {
        //console.log("getTickersFromSymbols START");
        const arrTickers : any[] = [];
        const arrFavoriteSymbols : any[] = [];
        let  theFirstSymbol = null;
        for (let i=0; i<this.arrSymbols.length; i++) {
            const tmpItem = (this.arrSymbols[i] as any);
            //console.log("getTickersFromSymbols EACH i: "+i+" Symbols: "+tmpItem.Symbols+" IsFavorite:  "+tmpItem.IsFavorite);
            if ( ""+tmpItem.IsFavorite === "true" ) {
                arrTickers.push(AppCore.prsCore_Game.genTicker(tmpItem.Symbols));
                arrFavoriteSymbols.push(tmpItem);
                if (prmIsInitByFirst && arrTickers.length === 1 ) {  // First Favorites Ticker
                    this.initSymbol(arrTickers[0].chartInfo.symbol);
                    //this.setDuration((tmpItem.Parities[0] as any).Duration);
                }
            }

            if (theFirstSymbol===null) {
                theFirstSymbol = tmpItem;
            }
        }

        if (arrTickers.length===0 && theFirstSymbol!==null) {
            arrTickers.push(AppCore.prsCore_Game.genTicker((theFirstSymbol as any).Symbols));
            arrFavoriteSymbols.push(theFirstSymbol);
            this.initSymbol(arrTickers[0].chartInfo.symbol);
        }

        store.dispatch(Actions.GAME_TICKERS, arrTickers);
        store.dispatch(Actions.GAME_FAVORITE_SYMBOLS, arrFavoriteSymbols);
    }

    public getDurationListFromSymbols() {
        const arrDurationList : any[] = [];
        for (let i=0; i<this.arrSymbols.length; i++) {
            const tmpSymbol = (this.arrSymbols[i] as any);
            if (tmpSymbol.Symbols === this.ccvSymbol) {
                for (let j=0; j<tmpSymbol.Parities.length; j++) {
                    const tmpParity = (tmpSymbol.Parities[j] as any);
                    arrDurationList.push(tmpParity);
                    if (j==0) {
                        this.setDuration(Number(tmpParity.Duration));
                    }
                }
            }
        }
        store.dispatch(Actions.GAME_DURATION_LIST, arrDurationList);
    }

    public checkAllSymbolsValidDayTimes(prmCallFrom) {
        //console.log("checkAllSymbolsValidDayTimes START "+prmCallFrom+" arrSymbols.length: "+this.arrSymbols.length)
        for (let i=0; i<this.arrSymbols.length; i++) {
            const tmpItem = (this.arrSymbols[i] as any);
            this.checkSymbolValidDayTimes(tmpItem);
        }
    }

    public checkSymbolValidDayTimes(prmSymbol) {
        //console.log("checkSymbolValidDayTimes START "+JSON.stringify(prmSymbol));


        //const dtNow = new Date();
        const dtNow = AppCore.prsCore_Game.getTUC(3);

        const vvDayOfWeek = libTools.method.getDayOfWeek(dtNow);
        const vvValidDay = prmSymbol.ValidDay[vvDayOfWeek-1];

        const idFavoriteText = "spn_SymbolDrawer_Favorite_Text_"+prmSymbol.Symbols.replace("/", "_");
        const idSymbolText = "spn_SymbolDrawer_Symbol_Text_"+prmSymbol.Type+"_"+prmSymbol.Symbols.replace("/", "_");

        //console.log("checkSymbolValidDayTimes_vA "+prmSymbol.Symbols+" vvDayOfWeek: "+vvDayOfWeek+" vvValidDay: "+vvValidDay+" idSymbolText: "+idSymbolText);

        const spn_SymbolDrawer_Favorite_Text = document.getElementById(idFavoriteText);
        const spn_SymbolDrawer_Symbol_Text = document.getElementById(idSymbolText);

        if (vvValidDay===true) {
            const vvValidTime = prmSymbol.ValidTime[vvDayOfWeek-1]; //{"StartTime":"00:00","FinishTime":"23:59"}
            //console.log("checkSymbolValidDayTimes VB vvDayOfWeek: "+vvDayOfWeek+" vvValidDay: "+vvValidDay+" vvValidTime: "+JSON.stringify(vvValidTime));
            const vvHourMin = dtNow.getHours().toString().padStart(2, '0') + ':' + dtNow.getMinutes().toString().padStart(2, '0');
            //console.log("checkSymbolValidDayTimes BC Times: "+vvValidTime.StartTime+" "+vvHourMin+" "+vvValidTime.FinishTime);
            if (vvValidTime.StartTime<=vvHourMin && vvHourMin<=vvValidTime.FinishTime) {
                //console.log("checkSymbolValidDayTimes RETURN TRUE");
                if (spn_SymbolDrawer_Favorite_Text!==null) {spn_SymbolDrawer_Favorite_Text.style.color="";}
                if (spn_SymbolDrawer_Symbol_Text!==null) {spn_SymbolDrawer_Symbol_Text.style.color=""; console.log("spn_SymbolDrawer_Symbol_Text SUC_FOUNDED RET_TRUE **** idSymbolText: "+idSymbolText);}
                return true;
            }
        }
        //console.log("checkSymbolValidDayTimes RETURN FALSE");
        if (spn_SymbolDrawer_Favorite_Text!==null) {spn_SymbolDrawer_Favorite_Text.style.color="#3F4149";}
        if (spn_SymbolDrawer_Symbol_Text!==null) {spn_SymbolDrawer_Symbol_Text.style.color="#3F4149"; console.log("spn_SymbolDrawer_Symbol_Text SUC_FOUNDED RET_FALSE **** idSymbolText: "+idSymbolText);}


        return false;
    }

    public setSymbolToFavorite(prmSymbolName, prmIsFavorite) {
        for (let i=0; i<this.arrSymbols.length; i++) {
            const tmpItem = (this.arrSymbols[i] as any);
            if (tmpItem.Symbols === prmSymbolName) {
                tmpItem.IsFavorite = ""+prmIsFavorite;
            }
        }
        this.getTickersFromSymbols(false);
    }

    public navigateToSignIn(prmCallFrom) {
        //console.log("navigateToSignIn prmCallFrom: "+prmCallFrom);
        //this.router = useRouter();
        //console.log("navigateToSignIn this.router: "+JSON.stringify(this.router));
        this.router.push({ name: this.getSignInUserPath(prmCallFrom) });
    }

    public getSignInUserPath(prmCallFrom) {
        //console.log("getSignInUserPath prmCallFrom: "+prmCallFrom);
        return "sign-in-user";
    }

    public processLogout() {
        AppCore.prsCore_Game.ccvLogoutStatus="refreshlogin";
        AppCore.prsCore_Game.prsChartDestroyChart();
        AppCore.prsCore_Game.setIsTraderActive(false);
        if (AppCore.prsCore_Game.wsStatus!=="None") {
            AppCore.prsCore_Game.ws.close();
        }
        store.dispatch(Actions.PROCESS_LOGOUT, "");
    }

    public chekcResult_For_LoginError(data) {
        console.log("chekcResult_For_LoginError START");
        if (data!==undefined && data!==null) {
            //console.log("chekcResult_For_LoginError v1 "+JSON.stringify(data));
            if (JSON.stringify(data).includes("Login Error")) {
                store.dispatch(Actions.PROCESS_LOGOUT, "CF_PROCESLOGOUT_RESULT_LOGINERROR");

                /*
                //console.log("chekcResult_For_LoginError v2a");
                AppCore.prsCore_Game.processLogout();
                //console.log("chekcResult_For_LoginError v2b");
                if (AppCore.prsCore_Game.router === undefined) {
                    console.log("chekcResult_For_LoginError v2b_Undefined");
                    AppCore.prsCore_Game.router = useRouter();
                }

                store.dispatch(Actions.LOGOUT).then(() => AppCore.prsCore_Game.router.push({ name: AppCore.prsCore_Game.getSignInUserPath("CF_NAVSIGIN_RESULT_LOGINERROR") }));
                console.log("chekcResult_For_LoginError v2c");
                 */
            }
        }
    }

    public initSymbol(prmSymbol) {
        this.ccvSymbol = prmSymbol;
    }

    public setSymbol(prmSymbol) {
        console.log("setSymbol "+prmSymbol);
        this.ccvSymbol = prmSymbol;
        store.dispatch(Actions.GAME_SYMBOL, prmSymbol);
    }

    public setOpenGameCount(prmOpenGameCount){
        this.ccvOpenGameCount = prmOpenGameCount;
    }

    public setBalance(prmBalance) {
        this.ccvBalance = Number(prmBalance);
        store.dispatch(Actions.GAME_BALANCE, Number(prmBalance));
    }

    public setBalanceDemo(prmBalanceDemo) {
        this.ccvBalanceDemo = Number(prmBalanceDemo);
        store.dispatch(Actions.GAME_BALANCE_DEMO, Number(prmBalanceDemo));
    }

    public getBalanceAmount() {
        if (this.ccvAccountType === "DEMO") {
            return Number(this.ccvBalanceDemo);
        }
        return Number(this.ccvBalance);
    }

    public getBalanceNameText() {
        if (this.ccvAccountType === "DEMO") {
            return "Demo Hesap";
        }
        return "Gerçek Hesap";
    }

    public getBalanceIconPath() {
        if (this.ccvAccountType === "DEMO") {
            return "media/icons/duotune/general/gen022.svg";
        }
        return "media/icons/duotune/general/gen037.svg";
    }

    public getBalanceIconClass() {
        if (this.ccvAccountType === "DEMO") {
            return "text-warning";
        }
        return "text-success";
    }

    public getBalanceColor1() {
        if (this.ccvAccountType === "DEMO") {
            return "#DAA03A";
        }
        return "rgb(59 183 59)";
    }

    public setArrSymbols(prmArrSymbols) {
        this.arrSymbols = prmArrSymbols;
    }

    public getSymbol_By_SymbolName(prmSymbolName) {
        //console.log("getSymbol_By_SymbolName START");
        //console.log("getSymbol_By_SymbolName prmSymbolName: "+prmSymbolName+" arrSymbols: "+JSON.stringify(this.arrSymbols))
        for (let i=0; i< this.arrSymbols.length; i++) {
            const tmpItem = this.arrSymbols[i] as any;
            if (tmpItem.Symbols===prmSymbolName) {
                return tmpItem;
            }
        }
        return null;
    }

    public getParity_By_Duration(prmSymbol, prmDuration) {
        for (let i=0; i<prmSymbol.Parities.length; i++) {
            const tmpItem = prmSymbol.Parities[i];
            if (Number(tmpItem.Duration) === Number(prmDuration)) {
                return tmpItem;
            }
        }
        return null;
    }

    public getSymbolRate_By_SymbolName(prmSymbolName) {
        const tmpSymbol = AppCore.prsCore_Game.getSymbol_By_SymbolName(prmSymbolName);
        if (tmpSymbol!==null) {
            return tmpSymbol.Rate;
        }
        return 0;
    }

    public setDuration (prmDuration) {
        this.ccvDuration = prmDuration;
        store.dispatch(Actions.GAME_DURATION, prmDuration);
        this.calcValuesBySymbols(false);
        if (mainjs!==null && mainjs.getFuncSetCloseTime()!==null) {
            mainjs.getFuncSetCloseTime()(prmDuration);
        }
    }

    public callFillChartDimensions() {
        if (mainjs!==null && mainjs.getFuncFillChartDimensions()!==null) {
            mainjs.getFuncFillChartDimensions()();
        }
    }

    public setWinRate(prmWinRate) {
        this.ccvWinRate = prmWinRate;
        store.dispatch(Actions.GAME_WINRATE, prmWinRate);
    }

    public setMinAmount(prmMinAmount) {
        this.ccvMinAmount = prmMinAmount;
        store.dispatch(Actions.GAME_MIN_AMOUNT, prmMinAmount);
    }

    public setMaxAmount(prmMaxAmount) {
        this.ccvMaxAmount = prmMaxAmount;
        store.dispatch(Actions.GAME_MAX_AMOUNT, prmMaxAmount);
    }

    public setAmount (prmAmount) {
        this.ccvAmount = prmAmount;
        store.dispatch(Actions.GAME_AMOUNT, prmAmount);
    }

    public getProperAmount(prmValue) {
        const rv = Number(prmValue);
        if (rv<this.ccvMinAmount) return this.ccvMinAmount;
        if (rv>this.ccvMaxAmount) return this.ccvMaxAmount;
        return rv;
    }

    public setAccountType (prmAccountType) {
        this.ccvAccountType = prmAccountType;
        store.dispatch(Actions.GAME_ACCOUNT_TYPE, prmAccountType);
    }

    public setIsLocal(prmIsLocal) {
        this.ccvIsLocal = prmIsLocal;
    }

    public calcValuesBySymbols(prmIsSetDurationToDefault) {
        //console.log("calcValuesBySymbols START v1 ccvSymbol: "+this.ccvSymbol+" ccvDuration: "+this.ccvDuration);

        this.ccvFavoriteDurationRate = 0;
        this.ccvFavoriteDifferentExist = false;

        this.theParity = null;

        let iCount = 0;

        for (let i=0; i<this.arrSymbols.length; i++) {
            const tmpSymbol = (this.arrSymbols[i] as any);
            if (tmpSymbol.Symbols === this.ccvSymbol) {

                if (prmIsSetDurationToDefault) {
                    if (iCount==0) {
                        this.ccvDuration = tmpSymbol.Duration;
                    }
                }

                for (let j=0; j<tmpSymbol.Parities.length; j++) {
                    const tmpParity = (tmpSymbol.Parities[j] as any);
                    //console.log("calcValuesBySymbols EACH_PARITY_A tmpSymbol.Symbols: "+tmpSymbol.Symbols+" tmpParity.Duration: "+tmpParity.Duration+" Rate: "+tmpParity.Rate);

                    //Calc Main Values of Symbol and Duration
                    if (Number(tmpParity.Duration) === Number(this.ccvDuration)) {
                        //console.log("calcValuesBySymbols DURATION_FOUNDED tmpParity.Duration: "+tmpParity.Duration);
                        this.setWinRate(Number(tmpParity.Rate));
                        this.setMinAmount(Number(tmpParity.MinAmount));
                        this.setMaxAmount(Number(tmpParity.MaxAmount));
                        this.calcAvgAmount();
                        this.setAmount(Number(tmpParity.MinAmount));
                        this.theParity = tmpParity;
                        this.funcUpEqualDown_ParityChanged(tmpParity);
                        //console.log("calcValuesBySymbols DURATION_FOUNDED "+i+" SET WinRate "+this.ccvWinRate+" ccvMinAmount: "+this.ccvMinAmount+" ccvAvgAmount: "+this.ccvAvgAmount);
                    }

                    //IsFavoriteDurationRate
                    if (this.ccvFavoriteDurationRate<Number(tmpParity.Rate)) {
                        if (this.ccvFavoriteDurationRate>0) {
                            //console.log("calcValuesBySymbols EACH FavDurRate_DiffExist "+i+" Symbols: "+tmpItem.Symbols+" ccvFavoriteDurationRate: "+this.ccvFavoriteDurationRate+" tmpItem.Rate: "+Number(tmpItem.Rate));
                            this.ccvFavoriteDifferentExist = true;
                        }
                        this.ccvFavoriteDurationRate=Number(tmpParity.Rate);

                    }
                }
                iCount++;

            }
        }
    }

    public calcAvgAmount() {
        this.ccvAvgAmount = this.calcAmountByRatio(50);
    }

    public calcAmountByRatio(prmRatio) {
        //console.log("");
        //console.log("calcAmountByRatio START "+prmRatio+" =================== ");
        const vvDiff = this.ccvMaxAmount;
        //console.log("calcAmountByRatio v1 vvDiff "+vvDiff);
        const vvRatio =  Math.floor(vvDiff * (prmRatio * 0.01));
        //console.log("calcAmountByRatio v2 vvRatio "+vvRatio);
        let vvRes = vvRatio;
        //console.log("calcAmountByRatio v3 vvRes "+vvRes);
        if (vvRes>this.ccvMaxAmount) vvRes = this.ccvMaxAmount;
        if (vvRes<this.ccvMinAmount) vvRes = this.ccvMinAmount;
        //console.log("calcAmountByRatio v4 vvRes "+vvRes);
        return vvRes;
    }

    public getAdviceAmounts() {
        const arrAdvices: number[] = [];
        arrAdvices.push(this.calcAmountByRatio(0));
        arrAdvices.push(this.calcAmountByRatio(25));
        arrAdvices.push(this.calcAmountByRatio(50));
        arrAdvices.push(this.calcAmountByRatio(75));
        arrAdvices.push(this.calcAmountByRatio(100));
        return arrAdvices;
    }

    public createSocket(prmIsReconnect) {
        // ws://localhost:3012
        // wss://staging-trader-socket.devoptions.app

        const vvUserInfo = this.getUserInfo();
        const stUri = this.getWssLink() + "/connecttrader?acode="+process.env.VUE_APP_ACODE + "&username=" + vvUserInfo.Username+"&"+"gamevalues"+this.getGameValues();
        //console.log("createSocket START prmIsReconnect: "+prmIsReconnect+" stUri: "+stUri);
        this.ws = new WebSocket(stUri);
        this.ws.addEventListener("open", () =>{
            //console.log("SOCKET-open - We are connected dt: "+(new Date()));
            this.wsStatus = "Connected";
            //this.ws.send("How are you?");

            if (prmIsReconnect) {
                this.sendSocketMes_GameValues("CF_GVL_Reconnect");
            } else {
                if (this.ccvSymbol!=='' && this.ccvDuration>0) {
                    this.sendSocketMes_GameValues("CF_GVL_FirstConnect");
                } else {
                    //console.log("createSocket SendGameValues check NOT_YET");
                }
            }
        });

        this.ws.addEventListener("close", () =>{
            //console.log("****************************************************");
            //console.log("SOCKET - We are Disconnected !!!! dt: "+(new Date()));
            //console.log("****************************************************");
            this.wsStatus = "DisConnected";
        });

        this.ws.addEventListener('message', function (event) {
            //console.log("WS_DATA_MESSAGE: "+  event.data);
            if (AppCore.prsCore_Game.ccvIsTraderActive) {
                const arrMain = event.data.split("_#_");
                if (arrMain.length>=2) {
                    const clmCmdName = arrMain[0];
                    const objPayload = JSON.parse(arrMain[1]);

                    //### NotLogined
                    if (clmCmdName==="NotLogined") {
                        Swal.fire({
                            text: "Login Süreniz Dolmuştur",
                            icon: "error", buttonsStyling: false,
                            confirmButtonText: "Tekrar Giriş Yapınız!",
                            customClass: { confirmButton: "btn fw-semobold btn-light-danger", },
                        }).then(function () {
                            AppCore.prsCore_Game.processLogout();
                            store.dispatch(Actions.LOGOUT).then(() => AppCore.prsCore_Game.router.push({ name: AppCore.prsCore_Game.getSignInUserPath("CF_NAVSIGIN_SOCKET01") }));
                        });
                    }

                    //### GameOpened
                    if (clmCmdName==="GameOpened") {
                        store.dispatch(Actions.SOCKET_RECEIVED_OPEN_GAME, objPayload);
                        const vvOpenDate = new Date(objPayload.dtOpenGame);
                        const cp = mainjs.getChartProcessor();
                        cp.createTransaction(
                            objPayload.StartRate,  //undefined
                            objPayload.Symbol,
                            vvOpenDate.getTime(),
                            Number(objPayload.Duration),
                            Number(objPayload.BetAmount),
                            Number(objPayload.WinRate) * 0.01,
                            Number(objPayload.PotentialEarningAmount),
                            objPayload.Bet,
                            objPayload.GameCode
                        );

                        store.dispatch(Actions.GAME_LISTS_ADD,
                            {
                                Environment: AppCore.prsCore_Game.ccvAccountType,
                                Code:objPayload.GameCode
                                , Status: "WAIT"
                                , Symbol: objPayload.Symbol
                                , BetAmount:objPayload.BetAmount
                                , Bet: objPayload.Bet
                                , WinRate: objPayload.WinRate
                                , Duration:objPayload.Duration
                                , StartDate:objPayload.dtOpenGame
                                , StartRate: objPayload.StartRate
                            } );
                        store.dispatch(Actions.SOCKET_RECEIVED_BALANCE_CHANGED, {BalanceLive:objPayload.Balance, Balance:objPayload.Balance, DemoBalance:objPayload.DemoBalance, BalanceDemo:objPayload.DemoBalance});

                        setTimeout(()=>{
                            AppCore.prsCore_Game.setIsOpenGameEnabled(true);
                        }, 150);


                    }

                    //### GameClosed
                    if (clmCmdName==="GameClosed") {
                        store.dispatch(Actions.SOCKET_RECEIVED_CLOSE_GAME, objPayload);
                        const vvCloseDate = new Date(objPayload.FinishDate);

                        const cp = mainjs.getChartProcessor();
                        cp.closeTransaction( objPayload.Code, vvCloseDate.getTime(), objPayload.GameResult, objPayload.ResultAmount);


                        if (objPayload.GameResult==="CANCEL") {
                            store.dispatch(Actions.GAME_LISTS_SETITEM_CANCEL, objPayload );
                            libTools.method.swalSuccess("Tamam", "Bozdurma işlemi tamamlanmıştır. ₺"+ objPayload.ResultAmount +" cüzdanınıza iade edilmiştir");
                        } else {
                            store.dispatch(Actions.GAME_LISTS_SETITEM_FINISHRATE, objPayload );
                        }

                        if (objPayload.GameResult==="WIN" || objPayload.GameResult==="CANCEL") {
                            store.dispatch(Actions.SOCKET_RECEIVED_BALANCE_CHANGED, {BalanceLive:objPayload.Balance, Balance:objPayload.Balance, DemoBalance:objPayload.DemoBalance, BalanceDemo:objPayload.DemoBalance});
                        }

                    }

                    //### BalanceChanged
                    if (clmCmdName==="BalanceChanged") {
                        //console.log("BALANCE CHANGED "+JSON.stringify( objPayload));
                        //store.dispatch(Actions.SOCKET_RECEIVED_BALANCE_CHANGED, objPayload);
                        store.dispatch(Actions.SOCKET_RECEIVED_BALANCE_CHANGED, {BalanceLive:objPayload.Balance, Balance:objPayload.Balance, DemoBalance:objPayload.DemoBalance, BalanceDemo:objPayload.DemoBalance});
                    }

                    //### JackpotInfos
                    if (clmCmdName==="JackpotInfos") {
                        //console.log("JACKPOT INFOS "+JSON.stringify( objPayload));
                        store.dispatch(Actions.SOCKET_RECEIVED_JACKPOT_INFOS, objPayload);
                    }

                    //### HeartBeat
                    if (clmCmdName==="HeartBeat") {
                        console.log("HEARTBEAT "+JSON.stringify( objPayload));
                        if (objPayload.islogined!==undefined) {
                            if (objPayload.islogined===0) {
                                store.dispatch(Actions.PROCESS_LOGOUT, "CF_PROCESLOGOUT_HEART_BEAT");
                            }
                        }
                        //store.dispatch(Actions.SOCKET_RECEIVED_BALANCE_CHANGED, {BalanceLive:objPayload.Balance, Balance:objPayload.Balance, DemoBalance:objPayload.DemoBalance, BalanceDemo:objPayload.DemoBalance});
                    }

                    //### TrendInfo
                    if (clmCmdName==="TrendInfo") {
                        //console.log("TRENDINFO "+JSON.stringify( objPayload));
                        store.dispatch(Actions.SOCKET_RECEIVED_TREND_INFOS, objPayload);
                    }

                    //### ResponseWarning1
                    if (clmCmdName==="ResponseWarning1") {
                        libTools.method.swalError("Response Warning", JSON.stringify(objPayload));
                    }

                    //### ShowError
                    if (clmCmdName==="ShowError") {
                        libTools.method.swalError(objPayload.ButtonText, AppCore.prsCore_Game.getText_By_TextCode(objPayload.TextCode));
                    }

                }

            }

        });
    }

    public sendSocketMes_OpenGame(prmType, prmDuration, prmAmount, prmSymbol) {
        //console.log("sendSocketMes_OpenGame START ccvIsOpenGameEnabled: "+this.ccvIsOpenGameEnabled);
        if (this.ccvIsOpenGameEnabled) {
            //console.log("sendSocketMes_OpenGame SENDING");
            const vvUserInfo = this.getUserInfo();
            this.ws.send ( "OpenGame" + "_#_" + vvUserInfo.Username+ "_|_"+vvUserInfo.Logincode + "_|_" + process.env.VUE_APP_ACODE +"_|_" + prmType + "_|_" + prmDuration + "_|_" + prmAmount + "_|_" + prmSymbol+"_|_" +this.ccvAccountType);
            this.tmcSentOpenGame = this.ccvSecond;
            this.setIsOpenGameEnabled(false);
        } else {
            console.log("sendSocketMes_OpenGame ccvIsOpenGameEnabled: "+this.ccvIsOpenGameEnabled+" OPENGAME_SENT_NOT_ENABLED")
        }
    }

    public sendSocketMes_CancelGame(prmGameCode) {
        this.ws.send ( "CancelGame" + "_#_" + prmGameCode);
    }

    public sendSocketMes_GameValues(prmFallFrom) {
        //console.log("sendSocketMes_GameValues START this.wsStatus: "+AppCore.prsCore_Game.wsStatus+" prmFallFrom: "+prmFallFrom);
        if (AppCore.prsCore_Game.wsStatus==="Connected") {
            const vvGameValues = AppCore.prsCore_Game.getGameValues();
            //console.log("sendSocketMes_GameValues v1 vvGameValues: "+JSON.stringify(vvGameValues));
            AppCore.prsCore_Game.ws.send ( "GameValues" + "_#_" + vvGameValues.Symbol + "_|_" + vvGameValues.Duration + "_|_" + vvGameValues.UserCode);
        }
    }

    public sendSocketMes_HeartBeat() {
        const uInfo = AppCore.prsCore_Game.getUserInfo();
        if (uInfo.Logincode!==undefined) {
            this.ws.send ( "HeartBeat" + "_#_"+uInfo.Logincode);
        }

    }

    public sendMessageToSocket(prmMessage) {
        this.ws.send(prmMessage);
    }

    public setIsOpenGameEnabled(prmIsOpenGameEnabled) {
        this.ccvIsOpenGameEnabled = prmIsOpenGameEnabled;
        if (prmIsOpenGameEnabled) {
            (document.getElementById("divUpDownEqual") as any).style.opacity=1.0;
        } else {
            (document.getElementById("divUpDownEqual") as any).style.opacity=0.7;
        }
    }

    public getUserInfo() {
        const stUserInfo = JwtService.getUserInfo();
        if (stUserInfo!==undefined && stUserInfo!==null) {
            return JSON.parse(stUserInfo);
        } else {
            return {"Username":"", Email: "", "Logincode": ""};
        }
    }

    public getDefaultUserInfo() {
        return {"Username":"", "Email": "", "Logincode": ""} ;
    }

    public getGameValues() {
        const uInfo = AppCore.prsCore_Game.getUserInfo();
        let vvUserCode = "";
        if (uInfo.UserCode!==undefined) {
            vvUserCode=uInfo.UserCode;
        }
        return {"Symbol": this.ccvSymbol, "Duration": this.ccvDuration, "UserCode": vvUserCode} ;
    }

    public getDefaultTickers() {
        return [
            { chartInfo: { symbol: "BTC/USD", company: "", tickSize: 0.01 },  valueFloatFixed: 0,  wsTickerName: "BTC/USD", },
            { chartInfo: { symbol: "ETH/USDT", company: "", tickSize: 0.01 },  valueFloatFixed: 0,  wsTickerName: "ETH/USDT", },
        ];
    }

    public genTicker(prmSymbol) {
        return { chartInfo: { symbol: prmSymbol,  company: "",  tickSize: 0.01, },  valueFloatFixed: 2,  wsTickerName: prmSymbol.replace("/", "/"), };
    }

    public changeSymbolTab(prmSymbol) {
        console.log("changeSymbolTab START "+prmSymbol);

        const theSymbol = AppCore.prsCore_Game.getSymbol_By_SymbolName(prmSymbol);
        if (AppCore.prsCore_Game.checkSymbolValidDayTimes(theSymbol)) {
            this.setSymbol(prmSymbol);
            if (mainjs!==null && mainjs.getFuncAddTicker()!==null) {
                console.log("Main PRS getFuncAddTicker ");
                mainjs.getFuncAddTicker()(AppCore.prsCore_Game.ccvSymbol);
            }
            this.getDurationListFromSymbols();
            store.dispatch(Actions.SYMBOLS_SELECTED, prmSymbol);
            //this.prsCheckValidTime();
            if (mainjs!==null && mainjs.getFuncClickTabSymbol()!==null) {
                console.log("Main PRS getFuncClickTabSymbol ");
                mainjs.getFuncClickTabSymbol()(AppCore.prsCore_Game.ccvSymbol);
            }
        } else {
            libTools.method.swalError("Tamam", AppCore.prsCore_Game.getText_By_TextCode("ERR_SYMBOL_NOT_VALID_TIME"));
        }


    }

    public getTickers() {
        if (mainjs!==null && mainjs.getFuncGetTickers()!==null) {
            console.log("Main PRS getFuncGetTickers ");
            const arrTickers = mainjs.getFuncGetTickers()();

            console.log("Main PRS getFuncGetTickers "+JSON.stringify(arrTickers));
            return arrTickers;
        }

        return null;

    }

    public setChartType (prmChartType) {
        this.ccvChartType = prmChartType;


        if (prmChartType==="mountain") {
            this.ccvChartTimeScale = 100;
        }

        if (prmChartType==="candle") {
            if (this.ccvChartCandleInterval==="m") {
                this.ccvChartTimeScale =  this.ccvChartCandlePeriod * 60  * 12;
            }
            if (this.ccvChartCandleInterval==="s") {
                this.ccvChartTimeScale =  this.ccvChartCandlePeriod * 12;
            }

        }

        console.log("setChartType TimeScale: "+this.ccvChartTimeScale+" ChartCandlePeriod: "+this.ccvChartCandlePeriod);

        this.prsSetChartSettings();
    }

    public setChartTimeScale (prmChartTimeScale, prmCandlePeriod, prmChartCandkeInterval) {

        //prmTimeScale: 720 prmCandlePeriod: 1 prmCandleInterval: m
        //prmTimeScale: 360 prmCandlePeriod: 30 prmCandleInterval: s
        //prmTimeScale: 180 prmCandlePeriod: 15 prmCandleInterval: s

        //Interval Saniye olursa 60 ile çarpma sadece 12 ile çarp
        //this.ccvChartTimeScale = Number(prmChartTimeScale) * 60 * 12;
        this.ccvChartTimeScale = Number(prmChartTimeScale) * 12;
        this.ccvChartCandlePeriod =  prmCandlePeriod;
        this.ccvChartCandleInterval = prmChartCandkeInterval;
        this.prsSetChartSettings();
    }

    public setChartCandlePeriod (prmChartCandlePeriod) {
        this.ccvChartCandlePeriod = Number(prmChartCandlePeriod);
        this.prsSetChartSettings();
    }

    public prsSetChartSettings() {
        if (mainjs!==null && mainjs.getFuncSetChartType()!==null) {
            //console.log("Main getFuncClickTabSymbol: "+mainjs.getFuncSetChartType());
            mainjs.getFuncSetChartType()(AppCore.prsCore_Game.ccvChartType, AppCore.prsCore_Game.ccvChartTimeScale, AppCore.prsCore_Game.ccvChartCandlePeriod, AppCore.prsCore_Game.ccvChartCandleInterval);
        }
    }

    public prsChartSwitchSection() {
        if (mainjs!==null && mainjs.getFuncSwitchSection()!==null) {
            //console.log("Main getFuncSwitchSection: "+mainjs.getFuncSwitchSection());
            mainjs.getFuncSwitchSection()();
        }
    }

    public prsChartSwitchTxtInfo() {
        if (mainjs!==null && mainjs.getFuncSwitchTxtInfo()!==null) {
            //console.log("Main getFuncSwitchTxtInfo: "+mainjs.getFuncSwitchTxtInfo());
            mainjs.getFuncSwitchTxtInfo()();
        }
    }

    public prsChartDestroyChart() {
        if (mainjs!==null && mainjs.getFuncDestroyChart()!==null) {
            // console.log("Main getFuncDestroyChart: "+mainjs.getFuncDestroyChart());
            mainjs.getFuncDestroyChart()();
        }
    }

    public checkIsChartDrawed() {
        const chartContainer = document.getElementById("chartContainer");
        if (chartContainer!==undefined && chartContainer!==null) {
            return chartContainer.classList.contains("scxRootContainer");
        }
        return false;
    }

    private getWssLink() {
        if (this.ccvIsLocal) {
            return "ws://localhost:3012";
        }
        return process.env.VUE_APP_WEB_SOCKET_LINK;
    }

    public timerTick_OneSec(prmThis) {
        if (prmThis.ccvIsTraderActive) {

            prmThis.ccvSecond++;

            const isChartDrawed = prmThis.checkIsChartDrawed();

            if (isChartDrawed && prmThis.tmcChartDrawed==0) {
                prmThis.tmcChartDrawed = prmThis.ccvSecond;
            }

            //console.log("timerTick_OneSec Tick ccvSecond: "+prmThis.ccvSecond+" wsStatus: "+prmThis.wsStatus+" isChartDrawed: "+isChartDrawed);

            if (!prmThis.ccvIsGameListSendAfterDraw && isChartDrawed && prmThis.ccvSecond - prmThis.tmcChartDrawed>1 ) {
                console.log("timerTick_OneSec ChartDrawed");
                prmThis.ccvIsGameListSendAfterDraw=true;
                prmThis.requestGameLists("ChartDrawed");

                if (prmThis.ccvIsSymbolTickerDifferent && prmThis.ccvSymbolTickerDifferentCurrentSymbolName!=="") {
                    prmThis.changeSymbolTab(prmThis.ccvSymbolTickerDifferentCurrentSymbolName);
                }

            }

            if (prmThis.ccvSecond - prmThis.tmcWsTryConnect >= 5) {
                if (prmThis.wsStatus=="DisConnected") {
                    prmThis.tmcWsTryConnect= prmThis.ccvSecond;
                    console.log("timerOneSec Socket TryingToReconnet "+prmThis.ccvSecond);
                    prmThis.createSocket(true);
                }
            }

            if (prmThis.ccvSecond - prmThis.tmcWsHeartBeat >= 20) {
                if (prmThis.wsStatus=="Connected") {
                    prmThis.sendSocketMes_HeartBeat();
                    prmThis.tmcWsHeartBeat = prmThis.ccvSecond;
                }
            }

            if (prmThis.tmcSentOpenGame > 0 && (prmThis.ccvSecond - prmThis.tmcSentOpenGame)>1) {
                console.log("SentOpenGame is Automatic_Enabled")
                prmThis.tmcSentOpenGame = 0;
                prmThis.setIsOpenGameEnabled(true);
            }

            prmThis.calcOpenGamesCountDowns();

            prmThis.callFillChartDimensions();


            if (AppCore.prsCore_Game.isMobile()) {
                AppCore.prsCore_Game.makeFullScreen();
            }

            if (prmThis.ccvSecond>3 && prmThis.ccvSecond % 30 === 0) {
                AppCore.prsCore_Game.checkAllSymbolsValidDayTimes("CF_SYMBOLVALID_TIMER");
                AppCore.prsCore_Game.prsCheckValidTime();
            }

        }

    }

    public prsCheckValidTime() {
        const theSymbol = AppCore.prsCore_Game.getSymbol_By_SymbolName(AppCore.prsCore_Game.ccvSymbol);
        if (theSymbol!==null && !AppCore.prsCore_Game.checkSymbolValidDayTimes(theSymbol)) {
            libTools.method.swalError("Tamam", AppCore.prsCore_Game.getText_By_TextCode("ERR_SYMBOL_NOT_VALID_TIME"));
        }
    }

    public requestGameLists(prmCallFrom) {
        store.dispatch(Actions.GAME_LISTS_LIST, { Environment:AppCore.prsCore_Game.ccvAccountType, limit: 20, Status: "OK", CallFrom: prmCallFrom  });
        store.dispatch(Actions.GAME_LISTS_LIST, { Environment:AppCore.prsCore_Game.ccvAccountType, limit: 200, Status: "WAIT", CallFrom: prmCallFrom  });
    }

    public calcOpenGamesCountDowns() {
        const arrCollections = document.getElementsByClassName("timerticker-processdrawer-a");

        //console.log("calcOpenGamesCountDowns START "+arrCollections.length);

        for (let i = 0; i < arrCollections.length; i++) {
            const vvElement = arrCollections[i] as HTMLElement;
            const vvId = vvElement.getAttribute("id");
            const theProcessItem = JSON.parse(vvElement.getAttribute("data-theProcessItem") as any);
            const vvEarlyCloseDuration = Number(vvElement.getAttribute("data-early-close-duration"));
            const vvEarlyCloseRate = Number(vvElement.getAttribute("data-early-close-rate"));
            const vvIdCode = vvElement.getAttribute("data-idcode");

            /*
            if (theProcessItem.CountDown===undefined) {
                theProcessItem.CountDown = theProcessItem.Duration;
            } else {
                theProcessItem.CountDown --;
            }

            let vvCountDown= theProcessItem.CountDown;
            vvElement.setAttribute("data-theProcessItem", JSON.stringify(theProcessItem));

            if (vvCountDown<1) {
                store.dispatch(Actions.GAME_LISTS_SETITEM, theProcessItem );
                vvCountDown = 1;
            }
            */

            if (theProcessItem.StartDateLocal===undefined || theProcessItem.StartDateLocal===null) {
                theProcessItem.StartDateLocal = theProcessItem.StartDate;
            }

            const dtStartDate = new Date(theProcessItem.StartDateLocal);

            const tmStart =  new Date( theProcessItem.StartDateLocal ).getTime();
            const vvDifSec = Math.floor((new Date().getTime() -tmStart) / 1000) ;

            let vvCountDown = ((Number(theProcessItem.Duration) - vvDifSec) ) ;

            if (vvCountDown<1) {

                store.dispatch(Actions.GAME_LISTS_SETITEM, theProcessItem );
                vvCountDown = 1;

                //Close Chart Transaction
                //const dtNow = new Date();
                //const cp = mainjs.getChartProcessor();
                //cp.closeTransaction( theProcessItem.Code, dtNow.getTime(), objPayload.GameResult, objPayload.ResultAmount);
            }

            //Check Cancel - EarlyClose
            //console.log("calcOpenGamesCountDowns EACH vvEarlyCloseDuration: "+vvEarlyCloseDuration+" vvDifSec: "+vvDifSec);

            const btnCancelEnabled = document.getElementById("btn_ProcessDrawer_"+vvIdCode+"_CancelEnabled_"+theProcessItem.Code) as any;
            const btnCancelDisabled = document.getElementById("btn_ProcessDrawer_"+vvIdCode+"_CancelDisabled_"+theProcessItem.Code) as any;
            const spanCountDownCancel = document.getElementById("span_ProcessDrawer_"+vvIdCode+"_CountDownCancel_"+theProcessItem.Code) as any;

            btnCancelEnabled.style.display = "none";
            btnCancelDisabled.style.display = "none";

            if (vvEarlyCloseDuration>0) {
                if (vvDifSec < vvEarlyCloseDuration ) {
                    btnCancelEnabled.style.display = "block";
                    btnCancelDisabled.style.display = "none";
                    if (vvEarlyCloseDuration - vvDifSec <= 5 ) {
                        spanCountDownCancel.innerHTML = ""+Number(vvEarlyCloseDuration - vvDifSec);
                        spanCountDownCancel.style.visibility = "";
                    } else {
                        spanCountDownCancel.innerHTML = Number(vvEarlyCloseRate).toFixed(0)+"%";
                        spanCountDownCancel.style.visibility = "";
                    }
                } else {
                    btnCancelEnabled.style.display = "none";
                    btnCancelDisabled.style.display = "block";
                }
            }



            vvElement.innerHTML = ""+vvCountDown;
            vvElement.style.visibility = "";
            //console.log("arrCollections "+theProcessItem.Code+" vvCountDown: "+vvCountDown);
            //console.log("arrCollections "+theProcessItem.Code+" dt1: "+theProcessItem.StartDate+" dt2: "+dtStartDate.toDateString()+" vvDifSec: "+vvDifSec+" vvCountDown: "+vvCountDown);
        }
    }

    public SetDefaultLocalDateAllData(prmPayload) {
        //console.log("SetDefaultLocalDateAllData START");
        if (prmPayload!==undefined && prmPayload!==null && prmPayload.data!==undefined && prmPayload.data!==null) {
            for (let i=0; i<prmPayload.data.length; i++) {
                const tmpItem = prmPayload.data[i];
                if (tmpItem.StartDateLocal===undefined) {
                    //date.setSeconds(date.getSeconds() - seconds);
                    tmpItem.StartDateLocal = new Date();
                    tmpItem.StartDateLocal.setSeconds(tmpItem.StartDateLocal.getSeconds() - (tmpItem.Duration - tmpItem.Countdown) );
                    //console.log("SetDefaultLocalDateAllData EACH_CHANGED StartDateLocal");
                }
            }
        }

        return prmPayload;
    }

    public gameListPrs_GetItem_By_GameCode(prmList, prmGameCode) {
        //console.log("getGameListItem_By_GameCode START");
        if (prmList!==undefined && prmList!==null && prmList.data!==undefined && prmList.data!==null ) {
            for (let i=0; i<prmList.data.length; i++) {
                const tmpItem = prmList.data[i];
                if (tmpItem.Code === prmGameCode) {
                    return tmpItem;
                }
                //console.log("getGameListItem_By_GameCode EACH i: "+i+" "+tmpItem.Code)
            }
        }
        return null;
    }

    public gameListPrs_AddItem(prmList, prmItem) {
        //console.log("gameListPrs_AddItem START");
        //console.log("gameListPrs_AddItem v1 prmList: "+JSON.stringify(prmList));
        const tmpItem = this.gameListPrs_GetItem_By_GameCode(prmList, prmItem.Code);
        if (tmpItem === null) {
            prmList.data.unshift(prmItem);
        }
        return prmList;
    }

    public gameListPrs_SetItem(prmList, prmItem, prmSetType) {
        //console.log("gameListPrs_SetItem START Code: "+prmItem.Code+" "+"prmSetType: "+prmSetType);
        //console.log("gameListPrs_SetItem v1 prmList: "+JSON.stringify(prmList));
        const tmpList = prmList;
        if (prmList!==undefined && prmList!==null && prmList.data!==undefined && prmList.data!==null ) {
            for (let i=0; i<tmpList.data.length; i++) {
                if (tmpList.data[i].Code === prmItem.Code) {
                    if (prmSetType==="SetStatus") {
                        tmpList.data[i].Status = "OK";
                        //console.log("gameListPrs_SetItem set_SUCC_A Code: "+prmItem.Code+" "+"prmSetType: "+prmSetType);
                    }
                    if (prmSetType==="SetFinishRate") {
                        tmpList.data[i].GameResult = prmItem.GameResult;
                        tmpList.data[i].ResultAmount = prmItem.ResultAmount;
                        tmpList.data[i].FinishRate = prmItem.FinishRate;
                        //console.log("gameListPrs_SetItem set_SUCC_B Code: "+prmItem.Code+" "+"prmSetType: "+prmSetType);
                    }

                    if (prmSetType==="SetCancel") {
                        tmpList.data[i].Status = "CANCEL";
                        tmpList.data[i].GameResult = prmItem.GameResult;
                        tmpList.data[i].ResultAmount = prmItem.ResultAmount;
                        tmpList.data[i].FinishRate = prmItem.FinishRate;
                        //console.log("gameListPrs_SetItem set_SUCC_C Code: "+prmItem.Code+" "+"prmSetType: "+prmSetType);
                    }

                }
            }

            if (tmpList.SetCounter===undefined) { tmpList.SetCounter = 1; }
            else { tmpList.SetCounter++; }

        }
        //console.log("gameListPrs_SetItem END SetCounter: "+tmpList.SetCounter+" tmpList: "+JSON.stringify(tmpList));
        return tmpList;
    }

    public getBrowserViewport () {
        const width = Math.max( document.documentElement.clientWidth,  window.innerWidth || 0);
        if (width < 576) return 'xs'
        if (width < 768) return 'sm'
        if (width < 992) return 'md'
        if (width < 1200) return 'lg'
        return 'xl';
    }

    public checkViewPort(){
        const isChanged_ViewPort = AppCore.prsCore_Game.setViewPort(AppCore.prsCore_Game.getBrowserViewport());
        const isChanged_Orientation = AppCore.prsCore_Game.setOrientation();
        if (isChanged_ViewPort || isChanged_Orientation) {
            console.log("checkViewPort Changed to "+AppCore.prsCore_Game.ccvViewPort+" "+AppCore.prsCore_Game.ccvOrientation+" isMobile: "+AppCore.prsCore_Game.isMobile());
            AppCore.prsCore_Game.checkViewPort_For_ProcessDrawer();
            AppCore.prsCore_Game.checkViewPort_For_SideBar();
            store.dispatch(Actions.GAME_VIEWPORT, AppCore.prsCore_Game.ccvViewPort);

        }
    }

    public checkViewPort_For_SideBar() {
        const divSidebar = document.getElementById("divSidebar") as HTMLElement;
        if (divSidebar!==null) {
            const elCont = document.getElementById("divProcessDrawerA1");
            if (elCont!==null) {
                if (elCont.classList.contains("show")) {
                    divSidebar.style.maxWidth = "475px"
                } else {
                    if (AppCore.prsCore_Game.ccvViewPort==="lg" || AppCore.prsCore_Game.ccvViewPort==="xl" ) {
                        divSidebar.style.maxWidth = "230px";
                    } else {
                        divSidebar.style.maxWidth = "202px";
                    }
                }
            }
        }
    }

    public checkViewPort_For_ProcessDrawer() {
        const divProcessDrawerA1 =  document.getElementById("divProcessDrawerA1") as HTMLElement;
        const divProcessDrawerA2 =  document.getElementById("divProcessDrawerA2") as HTMLElement;
        const divProcessDrawerB1 =  document.getElementById("divProcessDrawerB1") as HTMLElement;
        const divCover_ProcessDrawer = document.getElementById("divCover_ProcessDrawer") as HTMLElement;

        if (divProcessDrawerA1!==null) {

            if (AppCore.prsCore_Game.isMobile()) {
                if (AppCore.prsCore_Game.ccvViewPort==="lg" || AppCore.prsCore_Game.ccvViewPort==="xl" || AppCore.prsCore_Game.ccvViewPort==="md" || AppCore.prsCore_Game.ccvViewPort==="sm" ) {
                    divProcessDrawerA1.appendChild(divCover_ProcessDrawer);
                    AppCore.prsCore_Game.removeClassShow("divProcessDrawerB1");
                    AppCore.prsCore_Game.removeClassShow("divProcessDrawerA1");
                } else {
                    divProcessDrawerB1.appendChild(divCover_ProcessDrawer);
                }
            } else {

                if (AppCore.prsCore_Game.ccvViewPort==="lg" || AppCore.prsCore_Game.ccvViewPort==="xl" || AppCore.prsCore_Game.ccvViewPort==="md" || AppCore.prsCore_Game.ccvViewPort==="sm" ) {
                    divProcessDrawerA1.appendChild(divCover_ProcessDrawer);
                    AppCore.prsCore_Game.removeClassShow("divProcessDrawerB1");
                } else {
                    divProcessDrawerB1.appendChild(divCover_ProcessDrawer);
                }
            }


        }
    }

    public setViewPort(prmViewPort) {
        let isChanged = false;
        if (AppCore.prsCore_Game.ccvViewPort!==prmViewPort) {
            AppCore.prsCore_Game.ccvViewPort=prmViewPort;
            isChanged = true;
        }
        return isChanged;
    }

    public setOrientation() {
        let isChanged = false;

        if (window.matchMedia("(orientation: portrait)").matches && AppCore.prsCore_Game.ccvOrientation!=="portrait") {
            AppCore.prsCore_Game.ccvOrientation = "portrait";
            isChanged = true;
        }
        if (window.matchMedia("(orientation: landscape)").matches && AppCore.prsCore_Game.ccvOrientation!=="landscape") {
            AppCore.prsCore_Game.ccvOrientation = "landscape";
            isChanged = true;
        }
        return isChanged;
    }

    public isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }

    public getSymbolImage(prmSymbolName) {
        return "media/symbols/"+prmSymbolName.toLowerCase().replace("/", "-")+".png";
    }

    public getSymbolImageDefault() {
        return "media/symbols/default-icon.png";
    }

    public removeClassShow(prmContainerId) {
        const elCont = document.getElementById(prmContainerId) as HTMLElement;
        if (elCont!==null) {
            if (elCont.classList.contains("show")) {
                elCont.classList.remove("show");
            }
        }

    }

    public removeClass(prmElId, prmClass) {
        const elCont = document.getElementById(prmElId) as HTMLElement;
        if (elCont!==null) {
            if (elCont.classList.contains(prmClass)) {
                elCont.classList.remove(prmClass);
            }
        }
    }

    public addClass(prmElId, prmClass) {
        const elCont = document.getElementById(prmElId) as HTMLElement;
        if (elCont!==null) {
            if (!elCont.classList.contains(prmClass)) {
                elCont.classList.add(prmClass);
            }
        }
    }

    public toggleShow(prmElId) {
        const elCont = document.getElementById(prmElId) as HTMLElement;
        if (elCont!==null) {
            if (elCont.classList.contains("show")) {
                elCont.classList.remove("show");
            } else {
                elCont.classList.add("show");
            }
        }
    }

    public toggleClass(prmElId, prmClassName) {
        const elCont = document.getElementById(prmElId) as HTMLElement;
        if (elCont!==null) {
            if (elCont.classList.contains(prmClassName)) {
                elCont.classList.remove(prmClassName);
            } else {
                elCont.classList.add(prmClassName);
            }
        }
    }

    public toggleDisplay(prmElId) {
        const elCont = document.getElementById(prmElId) as HTMLElement;
        if (elCont!==null) {
            if (elCont.style.display==="none") {
                elCont.style.display = "";
            } else {
                elCont.style.display = "none";
            }
        }
    }

    public calcPasswordMeter(prmPass, prmMinChar) {
        //console.log("*************************")
        //console.log("calcMeter prmPass "+prmPass);

        let vvMeter = 0;
        let vvPercent = 0;
        let isFounded_SpChar = false;
        let isFounded_Number = false;
        let isFounded_UpperCase = false;
        let isFounded_LowerCase = false;
        let isFounded_MinChar = false;
        for (let i=0; i<prmPass.length; i++) {
            const eachChar = prmPass[i];

            //console.log("calcMeter START eachChar "+eachChar);


            if (!isFounded_SpChar && ("@!$'&#%/~|*+-_").includes(eachChar) ) {
                isFounded_SpChar = true;
                vvPercent+=1;
                //console.log("calcMeter isFounded_SpChar eachChar "+eachChar);
            }


            if (!isFounded_UpperCase && ("ABCDEFGHIİJKLMNOPQRSTUVWXYZ").includes(eachChar) ) {
                isFounded_UpperCase = true;
                vvPercent+=25;
                //console.log("calcMeter isFounded_UpperCase eachChar "+eachChar);
            }

            if (!isFounded_LowerCase && ("abcdefghiıjklmnopqrstuvwxyz").includes(eachChar) ) {
                isFounded_LowerCase = true;
                vvPercent+=25;
                //console.log("calcMeter isFounded_LowerCase eachChar "+eachChar);
            }

            if (!isFounded_Number && ("1234567890").includes(eachChar) ) {
                isFounded_Number = true;
                vvPercent+=25;
                //console.log("calcMeter isFounded_Number eachChar "+eachChar);
            }

            if (!isFounded_MinChar  && prmPass.length>=prmMinChar) {
                isFounded_MinChar = true;
                vvPercent+=25;
                //console.log("calcMeter isFounded_MinChar eachChar "+eachChar);
            }
        }

        //console.log("calcMeter vvPercent: "+vvPercent+" vvMeter: "+vvMeter);

        if (vvPercent>=100) { vvMeter=4;}
        else if (vvPercent>=75) { vvMeter=3;}
        else if (vvPercent>=50) { vvMeter=2;}
        else if (vvPercent>=25) { vvMeter=1;}
        else { vvMeter=0;}

        return {
            meter: vvMeter,
            isFounded_SpChar:isFounded_SpChar,
            isFounded_Number:isFounded_Number,
            isFounded_UpperCase:isFounded_UpperCase,
            isFounded_LowerCase: isFounded_LowerCase,
            isFounded_MinChar: isFounded_MinChar,
        };
    }

    public makeFullScreen() {
        const elem = document.documentElement as HTMLElement;
        if (elem.requestFullscreen) {
            //elem.requestFullscreen({ navigationUI: "show" });
            elem.requestFullscreen();
        }
    }

    public prsOpenGame(prmType) {
        console.log("click_StartGameItem START prmType: "+prmType);

        const vvDuration = AppCore.prsCore_Game.ccvDuration;
        const vvAmount = AppCore.prsCore_Game.ccvAmount;
        const vvBalance = AppCore.prsCore_Game.getBalanceAmount();
        const vvSymbol = AppCore.prsCore_Game.ccvSymbol;
        const vvOpenGameCount = AppCore.prsCore_Game.ccvOpenGameCount;
        //console.log("click_StartGameItem "+prmType+" vvDuration: "+vvDuration+" vvAmount: "+vvAmount+" vvBalance: "+vvBalance+" vvSymbol: "+vvSymbol+" valOpenCount: "+valOpenCount.value);

        const theSymbol = AppCore.prsCore_Game.getSymbol_By_SymbolName(vvSymbol);

        if (AppCore.prsCore_Game.checkSymbolValidDayTimes(theSymbol)) {
            if (vvBalance>=vvAmount) {
                if (vvOpenGameCount<AppCore.prsCore_Game.ccvMaxGameLimit) {
                    AppCore.prsCore_Game.sendSocketMes_OpenGame(prmType, vvDuration, vvAmount, vvSymbol);
                } else {
                    libTools.method.swalError("Tamam", "Anlık işlem açma limitiniz dolmuştur!");
                }
            } else {
                libTools.method.swalError("Tamam", "Bakiyeniz bu işlem için yeterli değildir!");
            }
        } else {
            libTools.method.swalError("Tamam", AppCore.prsCore_Game.getText_By_TextCode("ERR_SYMBOL_NOT_VALID_TIME"));
        }

    }

    public getText_By_TextCode(prmTextCode) {
        if (prmTextCode==="ERR_SYMBOL_NOT_VALID_TIME") return "Bu sembol şu anda işlem açma zaman aralığında yer almamaktadır. Lütfen başka bir sembol deneyiniz.";
        return "";
    }

    public getTUC(prmAddedHour) {
        const now = new Date();

        const year = now.getUTCFullYear();
        const month = now.getUTCMonth();
        const day = now.getUTCDate();
        const hours = now.getUTCHours();
        const minutes = now.getUTCMinutes();
        const seconds = now.getUTCSeconds();
        const milliseconds = now.getUTCMilliseconds();
        //console.log(`getTUC: ${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`);

        const dtUTC = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        const dtCalc = dtUTC;
        dtCalc.setTime(dtUTC.getTime() + (prmAddedHour * 60 * 60 * 1000));
        //console.log("getTUC ["+prmAddedHour+"]: "+dtCalc.toString());
        return dtCalc;
    }

}

export default PrsCore_Game;

